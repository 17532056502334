import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Tabla from '../../../../componentes/herramientas/tabla';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Ver_Valores, Form_todos, Titulos_todos, conexiones, genera_formulario, AExcell } from '../../../../constantes';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import IconButton from '@mui/material/IconButton';
import Esperar from '../../../../componentes/esperar/cargar';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TablaMultiple from '../../../../componentes/herramientas/tabla/tabla_multiple';

//Iconos
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Icon from '@mui/material/Icon';
import Formulario from '../../../../componentes/herramientas/formulario';
import { Calculo_notas, Nota_Entera, Notas_Promedio } from './comunes';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Scrollbars from '../../../../componentes/herramientas/scrolbars';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
    backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

  const Itemd = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
function DNotas (props) {
    const {Config} = props;
    // const estilos= Config && Config.Estilos.Usuarios ? Config.Estilos.Usuarios :Ver_Valores().config.Estilos.Usuarios ? Ver_Valores().config.Estilos.Usuarios : {} //props.config.Estilos.Usuarios ? props.config.Estilos.Usurios : {}
    // const classes= Estilos(estilos);
    const [state, setState]= useState({esperar:false});
    const [dialogo, setDialogo]= useState({
        open:false,  
    });
    let buscado=[];
    let Formularios;
    const Refrescar = ()=>{
        let formulario = state.formulario ? state.formulario : Formularios;
        let periodo= formulario.titulos[0].value.periodo.value;
        let grado = formulario.titulos[0].value.grado.value;
        let momento = formulario.titulos[0].value.momento.value;
        Ver_data(periodo, grado, momento, formulario)
    }
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    const Actualizar_data = (valores)=>{
        // console.log(valores)
        buscado=valores;
        // cambiarState({buscado:valores})
    }
    
    const Guardar = async(valores)=>{
        
        let {datos}=state;
        const pos =datos.findIndex(f=> f._id_estudiante===valores._id_estudiante);
        if (pos!=-1){
            valores.local=true;
            datos[pos]=Calculo_notas(valores)
        }else{
            datos=[...datos, valores]
        }
        datos=datos.sort((a,b)=>a.cedula>b.cedula ? 1 :-1);
        datos = datos.map((val,i)=>{return{posicion:i+1,...val}});
        
        cambiarState({datos})
        setDialogo({open:false})
    }
    const GuardarD = async(valores)=>{
        
        let {datos, docentes}=state;
        
        const pos =docentes.findIndex(f=> f._id_asignatura===valores._id_asignatura);
        if (pos!==-1){
            docentes[pos]={...docentes[pos],
                docente:`${valores.docente.apellidos}, ${valores.docente.nombres}`,
                cedula:valores.docente.cedula,
                _id_docente:valores.docente._id, local:true
            }
        }
        datos= datos.map(val=>{

            return {...val,
                [`asignatura_${valores._id_asignatura}`]:valores._id_asignatura,
                [`asignatura_${valores._id_asignatura}_nombre`]:valores.asignatura,
                [`asignatura_${valores._id_asignatura}_id_docente`]:valores.docente._id,
                [`asignatura_${valores._id_asignatura}_docente`]:`${valores.docente.apellidos} ${valores.docente.nombres}`,
                local:true
            }
        })
        cambiarState({datos, docentes})
        setDialogo({open:false})
    }
    const Abrir = async(valor) =>{
        
        let { asignaturas} = state;
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.nombres} {valor.apellidos}</Typography>    
                            </div>,
                    name:'titulo0',
                    nombre:'titulo0',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.periodo }</Typography>    
                            </div>,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:130, width:130},
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{valor.grado} {valor.seccion}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'label1',
                    title: ``,
                    formato:(valores)=>{
                        // let calcula= Calculo_notas(valores);
                        return  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{valor.grado} {valor.seccion}</Typography>
                                    
                                    {/* <Typography fontWeight={'bold'}>1er: {calcula[`1lapso-${datos.asignatura._id}`] ? calcula[`1lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`1lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`1lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>2do: {calcula[`2lapso-${datos.asignatura._id}`] ? calcula[`2lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`2lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`2lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>3er: {calcula[`3lapso-${datos.asignatura._id}`] ? calcula[`3lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`3lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`3lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography> */}
                                </div>
                    },
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo4',
                    nombre:'titulo4',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                }
                
            ]
        }
        let reparacion = [];
        asignaturas.map(val=>{
            formato.value=[...formato.value,
                {
                    label: `${val.sigla}`,
                    name:`${val._id}-def`,
                    nombre:`${val._id}-def`,
                    tipo:'notas',
                    title: `${val.asignatura}`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true
                            
                }
            ]
            reparacion=[...reparacion,
                {
                    label: `${val.sigla}`,
                    name:`${val._id}-rep`,
                    nombre:`${val._id}-rep`,
                    tipo:'notas',
                    title: `${val.asignatura}`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true
                            
                }
            ]
            return val;
        })
        formato.columna= asignaturas.length;
        formato.value=[...formato.value,
            {
                label: `REVISIÓN`,
                name:'titulo5',
                nombre:'titulo5',
                tipo:'mensaje1',
                title: ``,
                // variant:'h6',
                solo:true
            },
            ... reparacion
        ];
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:formato.columna>8 ?'l' : null,
            Titulo:`RESUMEN FINAL DEL RENDIMIENTO ESTUDIANTIL`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    
    const Abrird = async(valor) =>{
        
        let {periodo, grado, seccion} = state;
        
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.sigla} {valor.asignatura}</Typography>    
                            </div>,
                    name:'titulo0',
                    nombre:'titulo0',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{periodo }</Typography>    
                            </div>,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{grado.titulo} {seccion.titulo}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    solo:true
                },
                {
                    "nombre": "docente",
                    "tipo": "lista_multiuso",
                    "label": "Docente",
                    "placeholder": "Docente",
                    "title": "Docente",
                    "mensaje_error": "",
                    "disabled": false,
                    "numberOfLines": "",
                    "lista": "uecla_Docente",
                    "getOptionLabel": [
                        "apellidos", "nombres"
                    ],
                    "key": "docente",
                    "name": "docente",
                    "multiline": false,
                    "cargar":true
                },
                
            ]
        }
        
        
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        const pos = formulario.titulos[3].value.docente.lista.findIndex(f=>f._id===valor._id_docente);
        if (pos!==-1){
            formulario.titulos[3].value.docente.value= formulario.titulos[3].value.docente.lista[pos];
        }
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: GuardarD, validar:'true', 
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:formato.columna>8 ?'l' : null,
            Titulo:`Docente`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    
    const Ver_data = async(periodo, Grado, Momento, formulario=null)=>{
        cambiarState({esperar:true});
        var y = new Date().getFullYear();
        var y1= Number(y)-1;
        let actual = `${y1}-${y}`;
        periodo=periodo ? periodo.periodo : actual;
        const seccion = Grado.seccion//Seccion.titulo;
        const grado=Grado.grado 
        
        let docentes = [];
        Formularios= formulario!==null ? formulario : Formularios;
        
        
        const resultado = await conexiones.Leer_C(['uecla_NNota','uecla_Asigntura','uecla_Docente'],{
            uecla_NNota:{"valores.periodo":periodo,"valores.grado":grado, "valores.seccion":seccion},
            uecla_Asignatura:{"valores.grado.value":Grado.value},
            uecla_Docente:{}
        });
        
        if (resultado.Respuesta==='Ok'){
            // let datos = Notas_Promedio(resultado.datos.uecla_Asignatura, resultado.datos.uecla_NNota);
            // console.log(datos);
            const asignaturas =resultado.datos.uecla_Asignatura.sort((a,b)=>Number(a.valores.item)>Number(b.valores.item) ? 1:-1).map(val=>{
                const valor= val.valores;
                // titulos=[...titulos,{
                //     title:valor.sigla,
                //     field:valor._id,
                //     tipo:'',
                //     type:'',
                //     formato:(dato)=>{
                //         return Nota_Entera(dato[`${valor._id}-def`])
                //     }
                // }]
                docentes=[...docentes,{
                    sigla:valor.sigla, 
                    _id_asignatura:valor._id,
                    asignatura:valor.asignatura
                }]
                return {_id:val._id,...valor}
            });

            let datos = resultado.datos.uecla_NNota.sort((a,b)=> a.valores.cedula>b.valores.cedula ? 1 : -1).map((val,i)=>{
                
               return { _id:val._id, ...val.valores}
            });
            
            console.log(datos);
            
            cambiarState({datos, formulario, esperar:false, periodo, Grado, Momento,  asignaturas, docentes})
        }else{
            cambiarState({esperar:false, periodo, Grado, Momento, formulario, datos:[], docentes});
        }

    }
    const Cambio_dato = async (valores)=>{
        const {periodo, grado, momento}=valores.resultados;
        if (periodo===null || grado===null) return
        let formulario = state.formulario ? state.formulario : Formularios;
        formulario.titulos[0].value.grado.value=grado
        formulario.titulos[0].value.periodo.value=periodo
        formulario.titulos[0].value.momento.value=momento
        Formularios= formulario
        Ver_data(periodo, grado, momento, formulario)
        
    }
    const Inicio = async() =>{
        // cambiarState({esperar:true});
        let formulario = await genera_formulario({valores:{}, campos: Form_todos('Form_filtro_solvencias') })
        const periodos =formulario.titulos[0].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario.titulos[0].value.periodo.value= periodos[0];
        formulario.titulos[0].value.periodo.lista= periodos;
        
        let grados =  Ver_Valores().config.Listas.lista_colegio_grado;
        let seccion = Ver_Valores().config.Listas.lista_colegio_seccion;

        let ngrados=[];
        grados.map(val=>{
            let dato= seccion.map(sec=>{
                return {...val, titulo:`${val.titulo} ${sec.titulo}`, grado:val.titulo, seccion: sec.titulo}
            })
            ngrados=[...ngrados, ...dato]
            return dato
        })
        
        formulario.titulos[0].value.grado.value= ngrados[0];
        formulario.titulos[0].value.grado.lista=ngrados
        let momentos = [
            {_id:1, titulo:'1er Momento', value:'1lapso'},
            {_id:2, titulo:'2do Momento', value:'2lapso'},
            {_id:3, titulo:'3er Momento', value:'3lapso'},
        ]
        
        formulario.titulos[0].value.momento = {...formulario.titulos[0].value.grado};
        formulario.titulos[0].value.momento.value = momentos[0];
        formulario.titulos[0].value.momento.lista = momentos;
        formulario.titulos[0].value.momento.nombre='momento';
        formulario.titulos[0].value.momento.key='momento';
        formulario.titulos[0].value.momento.name='momento';
        formulario.titulos[0].value.momento.label='Promedio';
        formulario.titulos[0].value.momento.title='Promedio';
        formulario.titulos[0].value.grado.onChange= Cambio_dato;
        formulario.titulos[0].value.momento.onChange = Cambio_dato
        formulario.titulos[0].value.periodo.onChange= Cambio_dato;
        cambiarState({formulario})
        Formularios= formulario;
        
        Ver_data(periodos[0], ngrados[0],momentos[0], formulario)
        // Ver_data()
    }
    const Seleccion= async(estud)=>{
        
        let {datos, periodo, grado, seccion, asignaturas, docentes} = state;
        const pos =datos.findIndex(f=> f._id_estudiante===estud._id);
        if (pos!==-1){
            return
        }
        const dia = moment(estud.valores.fecha_nacimiento).format("DD");
        const mes = moment(estud.valores.fecha_nacimiento).format("MM");
        const ano = moment(estud.valores.fecha_nacimiento).format("YY");
        let notas = {};
        asignaturas.map(val=>{
            notas[`${val._id}-def`] = 0;
            notas[`${val._id}-rep`] = 0;
        })
        let data={
            _id_estudiante:estud._id,
            periodo, 
            grado:grado.titulo, 
            seccion:seccion.titulo,
            foto:estud.valores.foto,
            cedula:estud.valores.cedula,
            nombres:estud.valores.nombres,
            apellidos:estud.valores.apellidos,
            fecha_nacimiento:estud.valores.fecha_nacimiento,
            lugar_nacimiento:estud.valores.lugar_nacimiento,
            municipio:estud.valores.municipio,
            sexo:estud.valores.sexo,
            estado:estud.valores.estado,
            dia,mes,ano,
            local:true,
            ...notas
        }
        docentes.map(valores=>{
           data= {...data,
                [`asignatura_${valores._id_asignatura}`]:valores._id_asignatura,
                [`asignatura_${valores._id_asignatura}_nombre`]:valores.asignatura,
                [`asignatura_${valores._id_asignatura}_id_docente`]:valores._id_docente,
                [`asignatura_${valores._id_asignatura}_docente`]:`${valores.docente.split(',')[0]}${valores.docente.split(',')[1]}`
            }
            return{valores}
        })
        
        datos=[...datos, data].sort((a,b)=>a.cedula>b.cedula ? 1 :-1);
        datos = datos.map((val,i)=>{return{posicion:i+1,...val}});
        cambiarState({datos})
        setDialogo({open:false})
        Abrir(data)
    }
    const GuardarTodo=async()=>{
        let {datos, periodo, grado, seccion} = state;
        console.log(datos, periodo, grado, seccion)
        cambiarState({cargando:true});
        const lista = datos.filter(f=>f.local);
        for (var i=0; i<lista.length;i++){
            const nota = {...lista[i]};
            delete nota.local;
            delete nota._id_docente;
            delete nota.posicion;
            delete nota.dia;
            delete nota.mes;
            delete nota.ano;
            await conexiones.Guardar({_id:nota._id, valores:nota, multiples_valores:true},'uecla_NNota');
            
        }
        let formulario = state.formulario ? state.formulario : Formularios;
        formulario.titulos[0].value.grado.value=grado
        formulario.titulos[0].value.periodo.value=periodo
        formulario.titulos[0].value.seccion.value=seccion
        Formularios= formulario
        Ver_data({periodo}, grado, formulario)
    }
    const Agregar = async()=>{
        const {docentes}=state;
        let continuar = true;
        docentes.map(val=>{
            
            if (!val.docente || val.docente===''){
                continuar = false;
            }
            return val
        })
        if (!continuar){
            confirmAlert({
                title: `Docentes`,
                message:`Por favor indique todos los docentes para poder agregar estudiante`,
                buttons: [
                    {label: 'OK', onClick: async()=>{
                        
                    }},
                ]
            });
            return
        }
        const titulos = await Titulos_todos(`Titulos_Estudiante`, Ver_Valores().config)
        setDialogo({...state.dialogo,
            open:true,
            tam:'md',
            Titulo:`Estudiantes`,
            Cuerpo:
                    <TablaMultiple
                        alto={window.innerHeight * 0.62}
                        Config={Ver_Valores().config}
                        multiples_valores={true}
                        Agregar_mas={false}
                        Columnas={2} 
                        Form_origen = {Form_todos(`Form_Estudiante`, Ver_Valores().config)}
                        Titulo_tabla={'Estudiantes'}
                        Table = {'uecla_Estudiante'}
                        Eliminar_props={(dato)=>{
                            return `Desea eliminar al estudiante ${dato.nombres} ${dato.apellidos}`
                        }}
                        Titulo_dialogo ={"Ess"}
                        Titulos_tabla = {titulos}
                        cargaporparte = {true}
                        sinpaginacion = {false}
                        
                        Tam_dialogo={'xl'}
                        Seleccion={Seleccion}
                        Nuevo
                    />
                ,
            Cerrar: ()=>{
                setDialogo({open:false})
                
            },
        })
    }
   
    

    useEffect(()=>{
        
        Inicio();
        return ()=>{}
    }, [])

    const GuardarN=async(valores)=>{
    
        let nota = Calculo_notas(valores);
        
        const respuesta =await conexiones.Guardar({_id:nota._id, valores:nota, multiples_valores:true},'uecla_NNota');
        
        setDialogo({open:false});
        let {periodo, Grado, Momento} = state;
        let formulario = state.formulario ? state.formulario : Formularios;
        Ver_data({periodo}, Grado, Momento, formulario)
        return respuesta
        
    }
    const Detalles= async(valor, notas)=>{
        cambiarState({esperar:true});
        const data = [valor.lapso, valor._id];
        let docente='';
        let asignatura ='';
        const lapso = valor.lapso==='1lapso' ? '1er Lapso' : valor.lapso==='2lapso' ? '2do Lapso' : '3er Lapso';
        let dat_asig = {};
        Object.keys(notas).map(til=>{
            if ((til.indexOf(valor.lapso)!==-1 && til.indexOf(valor._id)!==-1) || til.indexOf(`asignatura_${valor._id}`)!==-1 || notas[til]===valor._id){
                dat_asig[til]=notas[til];
                docente = til.indexOf(`asignatura_${valor._id}`)!==-1 ? notas[`asignatura_${valor._id}_docente`] : docente;
                asignatura = til.indexOf(`asignatura_${valor._id}`)!==-1 ? notas[`asignatura_${valor._id}_nombre`] : asignatura;
                if(notas[til]===valor._id && til.split('-')[0]==='nota' && notas[`${til.split('-')[0]}-${til.split('-')[1]}-lapso`]===lapso){
                    let nota = til.split('-');
                    dat_asig[`${nota[0]}-${nota[1]}`]=notas[`${nota[0]}-${nota[1]}`];
                    dat_asig[`${nota[0]}-${nota[1]}-nombre`]=notas[`${nota[0]}-${nota[1]}-nombre`];
                    dat_asig[`${nota[0]}-${nota[1]}-lapso`]=notas[`${nota[0]}-${nota[1]}-lapso`];
                }
            }
            return til
        })
        
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{asignatura}</Typography>    
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{docente}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:130, width:130},
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{notas.grado} {notas.seccion}</Typography>
                                <Typography fontWeight={'bold'}>{lapso}</Typography>
                            </div>,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'label1',
                    title: ``,
                    formato:(datos)=>{
                        let calcula = {};
                        Object.keys(dat_asig).map(val=>{
                            calcula[val]= datos[val]
                            return
                        })
                        calcula= Calculo_notas(calcula);
                        
                        return  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                                    <Typography fontWeight={'bold'}>{lapso}</Typography>
                                    <Typography fontWeight={'bold'}>Nota: {calcula[`${data[0]}-${data[1]}`] ? calcula[`${data[0]}-${data[1]}`].toFixed(2) : '?'}</Typography>
                                </div>
                    },
                    // variant:'h6',
                    solo:false
                },
                {
                    label:  '',
                    name:'titulo4',
                    nombre:'titulo4',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:false
                },
                {
                    label:  '',
                    name:'titulo5',
                    nombre:'titulo5',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                }
                
            ]
        }
        let rasgos = [
            {
                label: `Rasgo`,
                name:`${data[0]}-${data[1]}-rasgos`,
                nombre:`${data[0]}-${data[1]}-rasgos`,
                tipo:'notas',
                disabled: true,
                title: `Rasgo`,
                type:'number',
                modificar:'Maximo_minimoR',
                sinicono:true,
            },
            {
                label: `Art112`,
                name:`${data[0]}-${data[1]}-art112`,
                nombre:`${data[0]}-${data[1]}-art112`,
                tipo:'notas',
                title: `Art112`,
                type:'number',
                modificar:'Maximo_minimo',
                sinicono:true,
            },
            {
                label: `Consejo`,
                name:`${data[0]}-${data[1]}-consejo`,
                nombre:`${data[0]}-${data[1]}-consejo`,
                tipo:'notas',
                title: `Consejo`,
                type:'number',
                modificar:'Maximo_minimo',
                sinicono:true,
            }
        ];

        //==============================================
        const nuevodatos = {
            periodo:notas.periodo, 
            grado:notas.grado,
            seccion:notas.seccion, 
            tipo:'docente', 
            asignatura:{
                asignatura:dat_asig[`asignatura_${data[1]}_nombre`],
                asignatura:dat_asig[`asignatura_${data[1]}_titulo`],
                _id:data[1],
            },
            docente:{
                _id:dat_asig[`asignatura_${data[1]}_id_docente`],

            }
        }
                    
        let nest_titulos = await Titulos_todos(`Titulos_Notas`, Config);
        nest_titulos = nest_titulos.map(val=>{
            return {...val, rowSpan:2}
        })
        const resultado = await conexiones.NNotas(nuevodatos);
    
        let fila1 = resultado.titulosn.datos[0].map(asig=>{
            return {
                default : "",
                field : `${asig.field}`,
                formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                orden: false, 
                tipo : "",
                title : asig.titulo,
                type : "number",
                lapso:asig.lapso,
                vertical:false,
                colSpan:7,                  

            }
        })
        
        const fila2 = resultado.titulosn.datos[1].map(asig=>{
            const definitiva = (asig.field.indexOf("1lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1)
                                && (asig.field.indexOf("-art112")===-1  && asig.field.indexOf("-rasgos")===-1)
            return {
                default : "",
                field : `${asig.field}`,
                formato : (dato)=> dato[asig.field] ? definitiva ? Nota_Entera(Number(dato[asig.field])) :Number(dato[asig.field]).toFixed(2) : '',
                orden: false, 
                tipo : "",
                title : asig.titulo,
                type : "number",
                lapso:asig.lapso,
                vertical:asig.vertical ? asig.vertical : false, 

            }
        })
        
        fila1 =[...nest_titulos, ...fila1];
        nest_titulos={filas:2 ,datos:[fila1,fila2], values:[...nest_titulos,...fila2]};
        //;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
        let agregarI=[];
        Object.keys(dat_asig).map(val=>{
            if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1
                && val.indexOf('lapso')===-1
                ){
                const pos = nest_titulos.values.findIndex(f=> `${f.field}`===val);
                if ((pos!==-1) ){
                    agregarI=[...agregarI,{
                        name: `filename-${val}`,
                        nombre: `filename-${val}`,
                        tipo: "Imagen",
                        label: `Imagen ${nest_titulos.values[pos].title}`,
                        title: `Imagen ${nest_titulos.values[pos].title}`,
                    }];
                    formato.value =[...formato.value,
                        {
                            label: `${nest_titulos.values[pos].title}`,
                            name:val,
                            nombre:val,
                            tipo:'notas',
                            title: `${nest_titulos.values[pos].title}`,
                            type:'number',
                            modificar:'Maximo_minimo',
                            sinicono:true,
                        }
                    ]
                }
                
            }
            return val
        })
        agregarI =  agregarI.length===0
                    ? []
                    : [...agregarI,
                        ...rasgos
        ]
        formato.columna= agregarI.length!==0 ? agregarI.length : 2;
        formato.value =[...formato.value,
            ...agregarI.length===0 
                    ? [
                        {
                            label: `SIN NOTAS`,
                            name:'titulo133',
                            nombre:'titulo133',
                            tipo:'mensaje1',
                            title: ``,
                            solo:true
                        }
                    ] 
                    :rasgos, 
        ];
        let formulario = await genera_formulario({ valores:{...notas}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                name:'guardar', label:'Guardar', title:'Guardar ',
                variant:"contained", color:"success", icono:<CheckIcon/>,
                onClick: GuardarN, validar:'true', 
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                name:'cancelar', label:'Cancelar', title:'Cancelar',
                variant:"contained",  icono:<CancelIcon/>,
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                onClick: ()=>setDialogo({open:false})
                },
            ]
        }
        cambiarState({esperar:false});
        setDialogo({
            open: true,
            //tam:'l',
            Titulo:`NOTAS  DE  "${notas.apellidos} ${notas.nombres}"`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({open:false})
        })
    }
    const NotasLapso = (notas)=>{
        
        const {Momento}=state;
        let asig = state.asignaturas;
        
        
        return asig.map(valor=>{
            let nota = notas[`${Momento.value}-${valor._id}-consejo`] 
                                ?   Number(notas[`${Momento.value}-${valor._id}-consejo`]).toFixed(2) 
                                :  notas[`${Momento.value}-${valor._id}-art112`] 
                                ?   Number(notas[`${Momento.value}-${valor._id}-art112`]).toFixed(2) 
                                :   notas[`${Momento.value}-${valor._id}`] 
                                ?   Number(notas[`${Momento.value}-${valor._id}`]).toFixed(2) : ''
                               
            const color=(valor.grado.value==='1er' && Number(valor.item)<8)
            || (valor.grado.value==='2do' && Number(valor.item)<8)
            || (valor.grado.value==='3er' && Number(valor.item)<9)
            || (valor.grado.value==='4to' && Number(valor.item)<10)
            || (valor.grado.value==='5to' && Number(valor.item)<11)
            ? '' :'#fca174';
            return  <Stack key={valor._id}
                        direction="row"
                        sx={{pl:1}}
                    >
                        <Typography variant="subtitle1" sx={{color ,width:'80%', textAlign:'left', fontWeight:'bold'}} noWrap>
                            {valor.asignatura}  
                        </Typography>
                        
                        <Typography variant="h6" sx={{color, width:'20%', textAlign:'right', fontWeight:'bold',cursor:'pointer', pr:1}} onClick={()=>Detalles({...valor, lapso:Momento.value},notas)}>
                            {Nota_Entera(nota)}
                        </Typography>
                    </Stack>
        })
        
    }
    const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    
    return !state.datos ? <Esperar open={true} Config={Config}/> : (
        <Box sx={{ flexGrow: 1, position: "relative"}}>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <Item style={{height: 110, color:'#fff'}}>
                        <Formulario {...state.formulario}
                            config={{
                                Estilos:{
                                    Input_label:{color:'#fff'},
                                    Input_fondo:'#fff',
                                    Input_input:{color:'#fff'}
                                }
                            }}
                        />
                    </Item>
                </Grid>
            </Grid>
            {/* <div style={{width:'100%', overflowX:'auto', backgroundColor:'#0f0',   }}> */}
            
            <Scrollbars sx={{bgcolor:'#d5d8dc',p:1, height:window.innerHeight * 0.75}}>
                {state.datos 
                    ?   state.datos.map((val,i)=>
                        <Itemd key={val._id} sx={{m:1, mb:2}}>
                            <Grid container spacing={0.5}>
                                <Grid xs={12} >
                                    
                                    <Stack direction="row" spacing={1}
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        
                                        <Stack direction="column" spacing={0.5} sx={{justifyContent: "center", alignItems: "center",}}>
                                            <Avatar alt={val.apellidos} src={val.foto} sx={{ width: 120, height: 120 }}/>
                                            <Typography variant="subtitle1"  sx={{fontWeight:'bold',width:250}}>
                                                {`${val.nombres}`}                
                                            </Typography>
                                            <Typography variant="subtitle1"  sx={{fontWeight:'bold', width:250}}>
                                                {`${val.apellidos}`}
                                            </Typography>
                                        </Stack>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Itemd >
                                                    <Stack direction="row" sx={{pl:1}}>
                                                        <Typography variant="subtitle" sx={{width:'80%', textAlign:'center', fontWeight:'bold'}} noWrap>
                                                            ASIGNATURA
                                                        </Typography>
                                                        
                                                        <Typography variant="subtitle" sx={{width:'20%', textAlign:'center', fontWeight:'bold'}}>
                                                            NOTAS
                                                        </Typography>
                                                    </Stack>
                                                    {NotasLapso(val)}
                                                    
                                                </Itemd>
                                            </Grid>
                                            
                                        </Grid>
                                    </Stack>
                                    
                                </Grid >
                                
                                   
                                        
                                    
                                
                            </Grid>
                        </Itemd>
                    )
                    
                    : null
                }
            </Scrollbars>
            
                <Dialogo  {...dialogo} config={Ver_Valores().config}/>
                <Esperar open={state.esperar}/>
                
            {/* </div> */}
        </Box>
    )
}

export default DNotas;