import moment from "moment";
import { NotasExcell, Titulos_todos, Ver_Valores } from "../../../../constantes";

export const Nota_Entera = (nota)=>{
    return Math.trunc(Number(nota)+0.5) 
}

export const NotaLiteral = (notas,nota, actual)=>{
    //notas: notas del estudiante
    //nota: el key de la nota que se desea mostar
    //actual: el valor actual que tiene la nota
    const materia = nota && nota!==undefined ? nota.split('-'): []
    if  (   materia.length>1 
            && ['PARTICIPACIÓN EN GRUPOS DE CREACIÓN, RECREACIÓN Y PRODUCCIÓN','ORIENTACIÓN Y CONVIVENCIA'].indexOf(notas[`asignatura_${materia[1]}_nombre`])!==-1
        )
    {
        
        return notas[nota] ? Literal(notas[nota]) : ''
    }else {
        return actual ? actual : nota ? notas[nota]: undefined;
    }
}
export const Literal = (valor)=>{
    const nota =Nota_Entera(valor);
    
    if (nota>=18){
        return 'A'
    }else if (nota>=15){
        return 'B'
    }else if (nota>=12){
        return 'C'
    }else if (nota>=10){
        return 'D'
    }else{
        return 'E'
    }
}
const Promedio = (nota)=>{
    let resultado =0;
    nota.map(val=>{
        resultado+=Nota_Entera(val);
        return val
    })
    resultado = nota.length !==0  ? resultado/nota.length : 0;
    if (isNaN(resultado)){
        return 0
    }
    return resultado
}

export const Notas_Promedio = (asignaturas, notas)=>{
    asignaturas = asignaturas.map(val=> val.valores ? val.valores : val).sort((a,b)=> Number(a.item)>Number(b.item) ? 1 : -1);
    asignaturas= asignaturas.filter(val=> 
            (val.grado.value==='1er' && Number(val.item)<8)
            || (val.grado.value==='2do' && Number(val.item)<8)
            || (val.grado.value==='3er' && Number(val.item)<9)
            || (val.grado.value==='4to' && Number(val.item)<10)
            || (val.grado.value==='5to' && Number(val.item)<11)
    )
    let datos=notas.map(val=>{
        //para calcular pormedio
        let pnotas = {lapso1:[],lapso2:[],lapso3:[], definitiva:[]}
        let notasc = {}
        Object.keys(val.valores).map(d=>{
            if ((d.indexOf('1lapso')!==-1 || d.indexOf('2lapso')!==-1 || d.indexOf('3lapso')!==-1)){
                const pos = asignaturas.findIndex(f=> f._id===d.split('-')[1]);
                if (pos!==-1){
                    // notasc[d]=  val.valores[d];
                    notasc[`${d.split('-')[0]}-${d.split('-')[1]}`]= val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-consejo`] 
                        ?  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-consejo`]
                        :  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-art112`] 
                        ?  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-art112`] 
                        :  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}`];
                    // notasc[`asignatura_${d.split('-')[1]}_nombre`]=val.valores[`asignatura_${d.split('-')[1]}_nombre`]
                }
            }else if (d.indexOf('-def')!==-1){
                pnotas.definitiva = [...pnotas.definitiva, Number(val.valores[d])];
            }
            return d;
        })
        Object.keys(notasc).map(d=>{
            if (d.indexOf('1lapso')!==-1){
                pnotas.lapso1= [...pnotas.lapso1, Number(notasc[d])];
            }else if (d.indexOf('2lapso')!==-1){
                pnotas.lapso2= [...pnotas.lapso2, Number(notasc[d])];
            }else if (d.indexOf('3lapso')!==-1){
                pnotas.lapso3= [...pnotas.lapso3, Number(notasc[d])];
            }
            return d
        })
        
        const lapso1= Promedio(pnotas.lapso1);
        const lapso2= Promedio(pnotas.lapso2);
        const lapso3= Promedio(pnotas.lapso3);
        const definitiva = Promedio(pnotas.definitiva);
        
        const promediol = definitiva!==0 ? definitiva : lapso1!==0 && lapso2!==0 && lapso3!==0 
            ? (lapso1+lapso2+lapso3)/3
            : lapso1!==0 && lapso2!==0 && lapso3===0 
            ? (lapso1+lapso2)/2
            : lapso1!==0 && lapso2===0 && lapso3===0 
            ? lapso1
            : 0
        return {...val.valores, lapso1, lapso2,lapso3, promediol, definitiva}
    })
    
    return datos
}

export const Promedios = (notas)=>{
    let promedio_total = 0;
    let promedio_momento1 = 0;
    let promedio_momento2 = 0;
    let promedio_momento3 = 0;
    let grados = {

    }

    
    notas.map(val=>{
        promedio_total+=val.promediol;
        promedio_momento1+= isNaN(val.lapso1) ? 0 : Number(val.lapso1);
        promedio_momento2+= isNaN(val.lapso2) ? 0 : Number(val.lapso2);
        promedio_momento3+= isNaN(val.lapso3) ? 0 : Number(val.lapso3);
        if (grados[`${val.grado} ${val.seccion}`]){
            grados[`${val.grado} ${val.seccion}`]={
                lapso1:grados[`${val.grado} ${val.seccion}`].lapso1 + val.lapso1,
                lapso2:grados[`${val.grado} ${val.seccion}`].lapso2 + val.lapso2,
                lapso3:grados[`${val.grado} ${val.seccion}`].lapso3 + val.lapso3,
                promedio:grados[`${val.grado} ${val.seccion}`].promedio + val.promediol, 
                cantidad: grados[`${val.grado} ${val.seccion}`].cantidad + 1
            }
        }else{
            grados[`${val.grado} ${val.seccion}`]={
                lapso1:val.lapso1,lapso2:val.lapso2,lapso3:val.lapso3,
                promedio:val.promediol, 
                cantidad:1
            }
        }
        return val;
    })
    let gradosD = Object.keys(grados).map(val=>{
        grados[val]={
            lapso1:Number(Number(grados[val].lapso1 / grados[val].cantidad).toFixed(2)),
            lapso2:Number(Number(grados[val].lapso2 / grados[val].cantidad).toFixed(2)),
            lapso3:Number(Number(grados[val].lapso3 / grados[val].cantidad).toFixed(2)),
            promedio:Number(Number(grados[val].promedio / grados[val].cantidad).toFixed(2)),
            cantidad:grados[val].cantidad,
        }
        return {...grados[val], seccion:val}
    }).sort((a,b)=> a.seccion>b.seccion ? 1 :-1)
    
    promedio_total = notas.length!==0 ? (promedio_total/notas.length) : 0;
    promedio_momento1 = notas.length!==0 ? (promedio_momento1/notas.length) : 0;
    promedio_momento2 = notas.length!==0 ? (promedio_momento2/notas.length) : 0;
    promedio_momento3 = notas.length!==0 ? (promedio_momento3/notas.length) : 0;

    return {promedio_total, promedio_momento1, promedio_momento2, promedio_momento3, grados, gradosD}
}

export const Calculo_notas = (valores)=>{
    let nota ={
        //asignatura: datos.asignatura,
        // docente: datos.docente
    };
    let notas= {};
    
    Object.keys(valores).map(val=>{
        
        if (val.indexOf('Error-')===-1 && valores[val]!=='' && val!=='id' 
        ){
            nota[val]=valores[val];
            const notad = val.split('-');
            if (val.indexOf('nota-')!==-1 && notad.length===2 && [null, undefined].indexOf(valores[val])===-1  ){
                const lapso = valores[`${val}-lapso`] === '1er Lapso' ? '1lapso' : valores[`${val}-lapso`] === '2do Lapso' ? '2lapso' : '3lapso';
                if (notas[valores[`${val}-asignatura`]]===undefined){
                    notas[valores[`${val}-asignatura`]]={
                        asignatura:valores[`asignatura_${valores[`${val}-asignatura`]}_nombre`],//valores[`${val}-asignatura-nombre`],
                        [`notas-${lapso}`]:[Number(valores[val])],
                        [`notas-${lapso}-nombre`]:[`${val} ${valores[`${val}-nombre`]} ${valores[`${val}-lapso`]}`]
                    }
                }else{
                    notas[valores[`${val}-asignatura`]]={
                        ...notas[valores[`${val}-asignatura`]],
                        [`notas-${lapso}`]:
                            notas[valores[`${val}-asignatura`]][`notas-${lapso}`] 
                                ?   [...notas[valores[`${val}-asignatura`]][`notas-${lapso}`], 
                                        Number(valores[val])
                                    ]
                                :   [Number(valores[val])],
                        [`notas-${lapso}-nombre`]:
                                notas[valores[`${val}-asignatura`]][`notas-${lapso}-nombre`] 
                                    ?   [...notas[valores[`${val}-asignatura`]][`notas-${lapso}-nombre`], 
                                            `${val} ${valores[`${val}-nombre`]} ${valores[`${val}-lapso`]}`
                                        ]
                                    :   [`${val} ${valores[`${val}-nombre`]} ${valores[`${val}-lapso`]}`],
                    }
                }
            }
            
        }
        return val;
    })
    
    Object.keys(nota).map(val=>{
        if (val.indexOf('lapso-')!==-1 && val.indexOf('-art112')===-1 && val.indexOf('-consejo')===-1 && val.indexOf('rasgos')===-1 && val.indexOf('-consejo')===-1){
            const data =  val.split('-');
            const lasnotas = notas[data[1]] ? notas[data[1]][`notas-${data[0]}`] : undefined;
            
            if (lasnotas!==undefined){
                let resul = 0; 
                lasnotas.map(res=>{
                    resul+=res;
                    return res
                })
                nota[val]= nota[val+'-rasgos']
                    ?   ((resul/lasnotas.length) * 0.90) + Number(nota[val+'-rasgos'] ? nota[val+'-rasgos'] : 0)
                    :   (resul/lasnotas.length)
            }else{
                nota[val]=null
            }   
        }
        return val
    })
    
    return nota
}

export const Formato_Boleta = async(est_datos, est_titulos, datos)=>{
    
    let campos = {
        No:'', apellidos:'apellidos', nombres:'nombres'
    };
    est_titulos.values.map(val=>{
        if (['Cedula','Nombres','Apellidos'].indexOf(val.title)===-1){
            campos={...campos, [val.field]:''}
        }
        return val
    })
    delete campos.cedula;
    campos={...campos,
        cedula:'', 
        fecha_nacimiento:'',
        lugar_nacimiento:'',
    }
    let data =[
        campos
    ]
    
    const agregar = data[0];
    for (var i=0;i<3;i++){
        data=[...data, agregar];
    }
    console.log(est_datos)
    est_datos.map((dat,i)=>{
        let nuevo = {No:i+1}
        Object.keys(campos).map(val=>{
            if (['No','_id_estudiante','seccion','grado', 'undefined'].indexOf(val)===-1){
                
                nuevo[val]= val==='fecha_nacimiento' ? moment(dat[val]).format("DD/MM/YYYY") :dat[val] === 0 ? '' : typeof dat[val]==='number' ? Nota_Entera(Number(dat[val])) : dat[val]
                
                nuevo[val]=NotaLiteral(dat, val, nuevo[val]);
                
            }
            
            return val
        })
        data=[...data, nuevo]
        return dat
    })
    let asigna=[];
    let trismestre=[];
    let subtitulo=['N°',"APELLIDOS Y NOMBRES","APELLIDOS Y NOMBRES"];
    est_titulos.datos[0].map(val=>{
        if (['Cedula','Nombres','Apellidos','ESTUDIANTES'].indexOf(val.title)===-1){
            asigna=[...asigna, val.title,val.title,val.title,val.title,val.title,val.title,val.title];
            trismestre=[...trismestre,"1°","1°","2°","2°","3°","3°",""];
            subtitulo=[...subtitulo,'C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','NOTA DEF.']
        }
        return val
    })
    
    asigna=[...asigna,'','','',''];
    subtitulo=[...subtitulo, 'CEDULA', 'FECHA','LUGAR',datos.grado, datos.seccion];
    
    const ano={
        '1er año':'PRIMER',
        '2do año':'SEGUNDO',
        '3er año':'TERCER',
        '4to año':'CUARTO',
        '5to año':'QUINTO'
    }
    const Titulos = [
        [`ASIGNATURAS`,"ASIGNATURAS", "ASIGNATURAS",...asigna],
        ["TRIMESTRE","TRIMESTRE","TRIMESTRE",...trismestre],
        subtitulo,
        ['No',`${ano[datos.grado]} AÑO SECCION "${datos.seccion}"`,"",""],
        ['No',`${datos.periodo}`,"",""]
        
    ];
    let file = await (await fetch("utilidad/boletas.xlsm")).arrayBuffer();
    console.log(data)
    NotasExcell(data, Titulos, file, "Notas", `Boletas-${datos.grado}-${datos.seccion}-${datos.periodo}.xlsm`, "boleta");
    // return {Titulos, data}
}

export const TitulosNotas = async(titulosn, ntipo)=>{
    const Config = Ver_Valores().config;
    let est_titulos = await Titulos_todos(`Titulos_Notas`, Config);
    est_titulos = est_titulos.map(val=>{
        return {...val, }
    })
    let fila1 = titulosn.datos[0].map(asig=>{
        return {
            default : "",
            field : `${asig.field}`,
            formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
            orden: false, 
            tipo : "",
            title : asig.titulo,
            type : "number",
            lapso:asig.lapso,
            vertical:false,
            colSpan:7,                  

        }
    })
                    
    const fila2 = titulosn.datos[1].map(asig=>{
        const definitiva = (asig.field.indexOf("1lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1 || asig.field.indexOf("3lapso-")!==-1)
                            && (asig.field.indexOf("-art112")===-1 && asig.field.indexOf("-rasgos")===-1) 
        const tipo =ntipo.value;
        
        return {
            default : "",
            field : `${asig.field}`,
            formato : (dato)=> dato[asig.field] 
                ?   definitiva 
                        ?   Nota_Entera(Number(tipo==='seccion' && dato[`${asig.field}-art112`] 
                                                ?   dato[`${asig.field}-art112`]
                                                :   dato[asig.field]
                                            ))
                        :   Number(dato[asig.field]).toFixed(2) 
                :   '',
            orden: false, 
            tipo : "",
            title : asig.titulo,
            type : "number",
            lapso:asig.lapso,
            vertical:asig.vertical ? asig.vertical : false, 

        }
    })
    est_titulos=[
        {
            ...est_titulos[0],
            fija:true, minWidth:400,//window.innerWidth * 0.3,
            title:'APELLIDOS NOMBRES',
            formato:(dato)=>{
                return `${dato.apellidos} ${dato.nombres}`
            }
        }
    ]
    fila1 =fila1.length!==0 ? [...est_titulos.map(val=>{return{...val, title:'ESTUDIANTES', orden:false}}), ...fila1] :[];
    est_titulos={filas:2 ,datos:[fila1,[...est_titulos, ...fila2]], values:[...est_titulos,...fila2]};
    return est_titulos
} 