import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Tabla from '../../../../componentes/herramientas/tabla';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Ver_Valores, Form_todos, Titulos_todos, conexiones, genera_formulario, AExcell } from '../../../../constantes';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import IconButton from '@mui/material/IconButton';
import Esperar from '../../../../componentes/esperar/cargar';
import Stack from '@mui/material/Stack';
import TablaMultiple from '../../../../componentes/herramientas/tabla/tabla_multiple';

//Iconos
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Icon from '@mui/material/Icon';
import Formulario from '../../../../componentes/herramientas/formulario';
import { Calculo_notas, Nota_Entera, Notas_Promedio } from './comunes';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
    backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

function ResumenF (props) {
    const {Config} = props;
    // const estilos= Config && Config.Estilos.Usuarios ? Config.Estilos.Usuarios :Ver_Valores().config.Estilos.Usuarios ? Ver_Valores().config.Estilos.Usuarios : {} //props.config.Estilos.Usuarios ? props.config.Estilos.Usurios : {}
    // const classes= Estilos(estilos);
    const [state, setState]= useState({esperar:false});
    const [dialogo, setDialogo]= useState({
        open:false,  
    });
    let buscado=[];
    let Formularios;
    const Refrescar = ()=>{
        let formulario = state.formulario ? state.formulario : Formularios;
        let periodo= formulario.titulos[0].value.periodo.value;
        let grado = formulario.titulos[0].value.grado.value;
        let momento = formulario.titulos[0].value.momento.value;
        Ver_data(periodo, grado, momento, formulario)
    }
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    const Actualizar_data = (valores)=>{
        // console.log(valores)
        buscado=valores;
        // cambiarState({buscado:valores})
    }
    
    const Guardar = async(valores)=>{
        
        let {datos}=state;
        const pos =datos.findIndex(f=> f._id_estudiante===valores._id_estudiante);
        if (pos!=-1){
            valores.local=true;
            datos[pos]=Calculo_notas(valores)
        }else{
            datos=[...datos, valores]
        }
        datos=datos.sort((a,b)=>a.cedula>b.cedula ? 1 :-1);
        datos = datos.map((val,i)=>{return{posicion:i+1,...val}});
        
        cambiarState({datos})
        setDialogo({open:false})
    }
    const GuardarD = async(valores)=>{
        
        let {datos, docentes}=state;
        
        const pos =docentes.findIndex(f=> f._id_asignatura===valores._id_asignatura);
        if (pos!==-1){
            docentes[pos]={...docentes[pos],
                docente:`${valores.docente.apellidos}, ${valores.docente.nombres}`,
                cedula:valores.docente.cedula,
                _id_docente:valores.docente._id, local:true
            }
        }
        datos= datos.map(val=>{

            return {...val,
                [`asignatura_${valores._id_asignatura}`]:valores._id_asignatura,
                [`asignatura_${valores._id_asignatura}_nombre`]:valores.asignatura,
                [`asignatura_${valores._id_asignatura}_id_docente`]:valores.docente._id,
                [`asignatura_${valores._id_asignatura}_docente`]:`${valores.docente.apellidos} ${valores.docente.nombres}`,
                local:true
            }
        })
        cambiarState({datos, docentes})
        setDialogo({open:false})
    }
    const Abrir = async(valor) =>{
        
        let { asignaturas} = state;
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.nombres} {valor.apellidos}</Typography>    
                            </div>,
                    name:'titulo0',
                    nombre:'titulo0',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.periodo }</Typography>    
                            </div>,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:130, width:130},
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{valor.grado} {valor.seccion}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'label1',
                    title: ``,
                    formato:(valores)=>{
                        // let calcula= Calculo_notas(valores);
                        return  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{valor.grado} {valor.seccion}</Typography>
                                    
                                    {/* <Typography fontWeight={'bold'}>1er: {calcula[`1lapso-${datos.asignatura._id}`] ? calcula[`1lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`1lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`1lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>2do: {calcula[`2lapso-${datos.asignatura._id}`] ? calcula[`2lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`2lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`2lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>3er: {calcula[`3lapso-${datos.asignatura._id}`] ? calcula[`3lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`3lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`3lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography> */}
                                </div>
                    },
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo4',
                    nombre:'titulo4',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                }
                
            ]
        }
        let reparacion = [];
        asignaturas.map(val=>{
            formato.value=[...formato.value,
                {
                    label: `${val.sigla}`,
                    name:`${val._id}-def`,
                    nombre:`${val._id}-def`,
                    tipo:'notas',
                    title: `${val.asignatura}`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true
                            
                }
            ]
            reparacion=[...reparacion,
                {
                    label: `${val.sigla}`,
                    name:`${val._id}-rep`,
                    nombre:`${val._id}-rep`,
                    tipo:'notas',
                    title: `${val.asignatura}`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true
                            
                }
            ]
            return val;
        })
        formato.columna= asignaturas.length;
        formato.value=[...formato.value,
            {
                label: `REVISIÓN`,
                name:'titulo5',
                nombre:'titulo5',
                tipo:'mensaje1',
                title: ``,
                // variant:'h6',
                solo:true
            },
            ... reparacion
        ];
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:formato.columna>8 ?'l' : null,
            Titulo:`RESUMEN FINAL DEL RENDIMIENTO ESTUDIANTIL`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    
    const Abrird = async(valor) =>{
        
        let {periodo, grado, seccion} = state;
        
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.sigla} {valor.asignatura}</Typography>    
                            </div>,
                    name:'titulo0',
                    nombre:'titulo0',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{periodo }</Typography>    
                            </div>,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{grado.titulo} {seccion.titulo}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    solo:true
                },
                {
                    "nombre": "docente",
                    "tipo": "lista_multiuso",
                    "label": "Docente",
                    "placeholder": "Docente",
                    "title": "Docente",
                    "mensaje_error": "",
                    "disabled": false,
                    "numberOfLines": "",
                    "lista": "uecla_Docente",
                    "getOptionLabel": [
                        "apellidos", "nombres"
                    ],
                    "key": "docente",
                    "name": "docente",
                    "multiline": false,
                    "cargar":true
                },
                
            ]
        }
        
        
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        const pos = formulario.titulos[3].value.docente.lista.findIndex(f=>f._id===valor._id_docente);
        if (pos!==-1){
            formulario.titulos[3].value.docente.value= formulario.titulos[3].value.docente.lista[pos];
        }
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: GuardarD, validar:'true', 
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:formato.columna>8 ?'l' : null,
            Titulo:`Docente`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    
    const Ver_data = async(periodo, Grado, Seccion, formulario=null)=>{
        cambiarState({esperar:true});
        var y = new Date().getFullYear();
        var y1= Number(y)-1;
        let actual = `${y1}-${y}`;
        periodo=periodo ? periodo.periodo : actual;
        const seccion = Seccion.titulo;
        const grado=Grado.titulo 
        let titulos = await Titulos_todos(`Titulos_Notas_resumen`, Config);
        let titulosd = await Titulos_todos(`Titulos_Notas_resumen_docentes`, Config);
        let docentes = [];
        Formularios= formulario!==null ? formulario : Formularios;
        
        
        const resultado = await conexiones.Leer_C(['uecla_NNota','uecla_Asigntura','uecla_Docente'],{
            uecla_NNota:{"valores.periodo":periodo,"valores.grado":grado, "valores.seccion":seccion},
            uecla_Asignatura:{"valores.grado.value":Grado.value},
            uecla_Docente:{}
        });
        
        if (resultado.Respuesta==='Ok'){
            // let datos = Notas_Promedio(resultado.datos.uecla_Asignatura, resultado.datos.uecla_NNota);
            // console.log(datos);
            const asignaturas =resultado.datos.uecla_Asignatura.sort((a,b)=>Number(a.valores.item)>Number(b.valores.item) ? 1:-1).map(val=>{
                const valor= val.valores;
                titulos=[...titulos,{
                    title:valor.sigla,
                    field:valor._id,
                    tipo:'',
                    type:'',
                    formato:(dato)=>{
                        return Nota_Entera(dato[`${valor._id}-def`])
                    }
                }]
                docentes=[...docentes,{
                    sigla:valor.sigla, 
                    _id_asignatura:valor._id,
                    asignatura:valor.asignatura
                }]
                return valor
            });

            let datos = resultado.datos.uecla_NNota.sort((a,b)=> a.valores.cedula>b.valores.cedula ? 1 : -1).map((val,i)=>{
                const dia = moment(val.valores.fecha_nacimiento).format("DD");
                const mes = moment(val.valores.fecha_nacimiento).format("MM");
                const ano = moment(val.valores.fecha_nacimiento).format("YY");

               return { posicion:i+1, dia, mes, ano, _id:val._id, ...val.valores}
            });
            
            if (datos.length!==0){
                const valor = datos[0];
                docentes = docentes.map((val,i)=>{
                    let profesor = valor[`asignatura_${val._id_asignatura}_docente`] ? valor[`asignatura_${val._id_asignatura}_docente`] : '';
                    let cedula ='';
                    let _id_docente=null;
                    if (profesor!==''){
                        const pos = resultado.datos.uecla_Docente.findIndex(f=> f._id===valor[`asignatura_${val._id_asignatura}_id_docente`])
                        if (pos!==-1){
                            cedula = resultado.datos.uecla_Docente[pos].valores.cedula;
                            profesor = `${resultado.datos.uecla_Docente[pos].valores.apellidos}, ${resultado.datos.uecla_Docente[pos].valores.nombres}`;
                            _id_docente =resultado.datos.uecla_Docente[pos]._id;
                        }
                    }
                    return {posicion:i+1, ...val,
                        docente:profesor,
                        cedula, _id_docente
                    }
                })
                // Object.keys(datos[0]).map(til=>{
                //     if (til.indexOf('asignatura_')!==-1){
                //         paradocentes={...paradocentes, [til]:datos[0][til]}
                //     }
                //     return til
                // })
            }
            
            cambiarState({datos, formulario, esperar:false, periodo, grado:Grado, seccion:Seccion, titulos, asignaturas, titulosd, docentes})
        }else{
            cambiarState({esperar:false, periodo, grado:Grado, seccion:Seccion, formulario,titulos, titulosd, datos:[], docentes});
        }

    }
    const Cambio_dato = async (valores)=>{
        const {periodo, grado, seccion}=valores.resultados;
        if (periodo===null || grado===null) return
        let formulario = state.formulario ? state.formulario : Formularios;
        formulario.titulos[0].value.grado.value=grado
        formulario.titulos[0].value.periodo.value=periodo
        formulario.titulos[0].value.seccion.value=seccion
        Formularios= formulario
        Ver_data(periodo, grado, seccion, formulario)
        
    }
    const Inicio = async() =>{
        // cambiarState({esperar:true});
        let formulario = await genera_formulario({valores:{}, campos: Form_todos('Form_Notas_anterior') })
        const periodos =formulario.titulos[0].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario.titulos[0].value.periodo.value= periodos[0];
        formulario.titulos[0].value.periodo.lista= periodos;
        formulario.titulos[0].value.grado.value=formulario.titulos[0].value.grado.lista[0]
        formulario.titulos[0].value.seccion.value = formulario.titulos[0].value.seccion.lista[0] ;
        formulario.titulos[0].value.periodo.onChange= Cambio_dato;
        formulario.titulos[0].value.grado.onChange= Cambio_dato;
        formulario.titulos[0].value.seccion.onChange= Cambio_dato;
        cambiarState({formulario})
        Formularios= formulario;
        
        Ver_data(periodos[0], formulario.titulos[0].value.grado.lista[0], formulario.titulos[0].value.seccion.lista[0], formulario)
        // Ver_data()
    }
    const Seleccion= async(estud)=>{
        
        let {datos, periodo, grado, seccion, asignaturas, docentes} = state;
        const pos =datos.findIndex(f=> f._id_estudiante===estud._id);
        if (pos!==-1){
            return
        }
        const dia = moment(estud.valores.fecha_nacimiento).format("DD");
        const mes = moment(estud.valores.fecha_nacimiento).format("MM");
        const ano = moment(estud.valores.fecha_nacimiento).format("YY");
        let notas = {};
        asignaturas.map(val=>{
            notas[`${val._id}-def`] = 0;
            notas[`${val._id}-rep`] = 0;
        })
        let data={
            _id_estudiante:estud._id,
            periodo, 
            grado:grado.titulo, 
            seccion:seccion.titulo,
            foto:estud.valores.foto,
            cedula:estud.valores.cedula,
            nombres:estud.valores.nombres,
            apellidos:estud.valores.apellidos,
            fecha_nacimiento:estud.valores.fecha_nacimiento,
            lugar_nacimiento:estud.valores.lugar_nacimiento,
            municipio:estud.valores.municipio,
            sexo:estud.valores.sexo,
            estado:estud.valores.estado,
            dia,mes,ano,
            local:true,
            ...notas
        }
        docentes.map(valores=>{
           data= {...data,
                [`asignatura_${valores._id_asignatura}`]:valores._id_asignatura,
                [`asignatura_${valores._id_asignatura}_nombre`]:valores.asignatura,
                [`asignatura_${valores._id_asignatura}_id_docente`]:valores._id_docente,
                [`asignatura_${valores._id_asignatura}_docente`]:`${valores.docente.split(',')[0]}${valores.docente.split(',')[1]}`
            }
            return{valores}
        })
        
        datos=[...datos, data].sort((a,b)=>a.cedula>b.cedula ? 1 :-1);
        datos = datos.map((val,i)=>{return{posicion:i+1,...val}});
        cambiarState({datos})
        setDialogo({open:false})
        Abrir(data)
    }
    const GuardarTodo=async()=>{
        let {datos, periodo, grado, seccion} = state;
        console.log(datos, periodo, grado, seccion)
        cambiarState({cargando:true});
        const lista = datos.filter(f=>f.local);
        for (var i=0; i<lista.length;i++){
            const nota = {...lista[i]};
            delete nota.local;
            delete nota._id_docente;
            delete nota.posicion;
            delete nota.dia;
            delete nota.mes;
            delete nota.ano;
            await conexiones.Guardar({_id:nota._id, valores:nota, multiples_valores:true},'uecla_NNota');
            
        }
        let formulario = state.formulario ? state.formulario : Formularios;
        formulario.titulos[0].value.grado.value=grado
        formulario.titulos[0].value.periodo.value=periodo
        formulario.titulos[0].value.seccion.value=seccion
        Formularios= formulario
        Ver_data({periodo}, grado, seccion, formulario)
    }
    const Agregar = async()=>{
        const {docentes}=state;
        let continuar = true;
        docentes.map(val=>{
            
            if (!val.docente || val.docente===''){
                continuar = false;
            }
            return val
        })
        if (!continuar){
            confirmAlert({
                title: `Docentes`,
                message:`Por favor indique todos los docentes para poder agregar estudiante`,
                buttons: [
                    {label: 'OK', onClick: async()=>{
                        
                    }},
                ]
            });
            return
        }
        const titulos = await Titulos_todos(`Titulos_Estudiante`, Ver_Valores().config)
        setDialogo({...state.dialogo,
            open:true,
            tam:'md',
            Titulo:`Estudiantes`,
            Cuerpo:
                    <TablaMultiple
                        alto={window.innerHeight * 0.62}
                        Config={Ver_Valores().config}
                        multiples_valores={true}
                        Agregar_mas={false}
                        Columnas={2} 
                        Form_origen = {Form_todos(`Form_Estudiante`, Ver_Valores().config)}
                        Titulo_tabla={'Estudiantes'}
                        Table = {'uecla_Estudiante'}
                        Eliminar_props={(dato)=>{
                            return `Desea eliminar al estudiante ${dato.nombres} ${dato.apellidos}`
                        }}
                        Titulo_dialogo ={"Ess"}
                        Titulos_tabla = {titulos}
                        cargaporparte = {true}
                        sinpaginacion = {false}
                        
                        Tam_dialogo={'xl'}
                        Seleccion={Seleccion}
                        Nuevo
                    />
                ,
            Cerrar: ()=>{
                setDialogo({open:false})
                
            },
        })
    }
   
    

    useEffect(()=>{
        
        Inicio();
        return ()=>{}
    }, [])

    
    const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    
    return !state.titulos ? <Esperar open={true} Config={Config}/> : (
        <Box sx={{ flexGrow: 1, position: "relative"}}>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <Item style={{height: 110, color:'#fff'}}>
                        <Formulario {...state.formulario}
                            config={{
                                Estilos:{
                                    Input_label:{color:'#fff'},
                                    Input_fondo:'#fff',
                                    Input_input:{color:'#fff'}
                                }
                            }}
                        />
                    </Item>
                </Grid>
            </Grid>
            {/* <div style={{width:'100%', overflowX:'auto', backgroundColor:'#0f0',   }}> */}
            <Tabla  Titulo={"Profesores por Áreas"}
                        alto={Ver_Valores().tipo==='Web' ? window.innerHeight * 0.34 : window.innerHeight * 0.73}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={state.titulosd}
                        table={'uecla_Docente'}
                        cantidad={state.docentes ? state.docentes.length : null}
                        // cargacompleta={Actualizar_data}
                        datos={state.docentes}
                        Accion={Abrird}
                        cargaporparte={false }
                        Noactualizar
                        sinpaginacion={true}
                        
                        
                />
                <Tabla  Titulo={`Notas (${state.periodo} ${state.grado.titulo} ${state.seccion.titulo})`}
                        alto={Ver_Valores().tipo==='Web' ? window.innerHeight * 0.64 : window.innerHeight * 0.73}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={state.titulos}
                        table={'uecla_NNota'}
                        cantidad={state.cantidad ? state.cantidad : null}
                        // cargacompleta={Actualizar_data}
                        datos={state.datos}
                        Accion={Abrir}
                        cargaporparte={false }
                        Noactualizar
                        sinpaginacion={true}
                        acciones={
                            state.formulario ?
                            <Stack direction="row" spacing={0.5}>
                                
                                <IconButton sx={{ p: '10px' }} title={'Agregar estudiante'} onClick={Agregar}>
                                    <Icon sx={{color}}>post_add</Icon>
                                </IconButton>
                                
                                {state.datos && state.datos.filter(f=>f.local).length!==0
                                    ?   
                                        !state.cargando
                                        ?   <div>
                                                <IconButton color={'primary'} title={'Guardar Cambios'} onClick={GuardarTodo}>
                                                    <Icon style={color}>save</Icon>
                                                </IconButton>
                                            </div>
                                        :  <CircularProgress  /> 
                                    :   null
                                }
                                
                            </Stack>
                            : null
                        }
                        
                />
                
                <Dialogo  {...dialogo} config={Ver_Valores().config}/>
                <Esperar open={state.esperar}/>
                
            {/* </div> */}
        </Box>
    )
}

export default ResumenF;