import React, {useState, useEffect} from 'react';
import Tabla from '../../../../componentes/herramientas/tabla';

import { Ver_Valores, Form_todos, Titulos_todos, conexiones, genera_formulario, AExcell } from '../../../../constantes';
import Dialogo from '../../../../componentes/herramientas/dialogo';
import IconButton from '@mui/material/IconButton';
import Esperar from '../../../../componentes/esperar/cargar';
import Stack from '@mui/material/Stack';

//Iconos
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Icon from '@mui/material/Icon';
import Formulario from '../../../../componentes/herramientas/formulario';
import { Notas_Promedio } from './comunes';

function Notast (props) {
    const {Config} = props;
    // const estilos= Config && Config.Estilos.Usuarios ? Config.Estilos.Usuarios :Ver_Valores().config.Estilos.Usuarios ? Ver_Valores().config.Estilos.Usuarios : {} //props.config.Estilos.Usuarios ? props.config.Estilos.Usurios : {}
    // const classes= Estilos(estilos);
    const [state, setState]= useState({esperar:false});
    const [dialogo, setDialogo]= useState({
        open:false,  
    });
    let buscado=[];
    let Formularios;
    const Refrescar = ()=>{
        let formulario = state.formulario ? state.formulario : Formularios;
        let periodo= formulario.titulos[0].value.periodo.value;
        let grado = formulario.titulos[0].value.grado.value;
        let momento = formulario.titulos[0].value.momento.value;
        Ver_data(periodo, grado, momento, formulario)
    }
    
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    const Actualizar_data = (valores)=>{
        // console.log(valores)
        buscado=valores;
        // cambiarState({buscado:valores})
    }
    
    const Guardar = async(valores, campos)=>{
        const meses = Ver_Valores().config.Listas.lista_Meses;
        meses.map(val=>{
          valores[val.value]=valores[`mensaje-${val.value}`].value;
          valores[`mensaje-${val.value}`]= valores[`mensaje-${val.value}`]._id===0 ? '' : valores[`mensaje-${val.value}`].titulo
          return val
        });
        let nuevos= await conexiones.Guardar({_id:valores._id, campos, valores, multiples_valores:true},'uecla_Mensualidad');
        if (nuevos.Respuesta==='Ok'){
            setDialogo({...dialogo,open:false})
            Refrescar();
        }
        return nuevos
    }
    const Abrir = async(valores) =>{
        const lista = Ver_Valores().config.Listas.lista_mensaje_meses;
        const meses = Ver_Valores().config.Listas.lista_Meses;
        let estudiante = await conexiones.Leer_C(['uecla_Estudiante'],{uecla_Estudiante:{_id:valores._id_estudiante}})
        if (estudiante.Respuesta==='Ok'){
            estudiante= estudiante.datos.uecla_Estudiante.length!==0 ? estudiante.datos.uecla_Estudiante[0].valores : {};
        }else{
            estudiante = {};
        }
        valores.grado = `${estudiante.grado ? estudiante.grado.titulo : ''} ${estudiante.seccion ? estudiante.seccion.titulo : '' }`
        meses.map(val=>{
          if (valores[val.value]){
            valores['mensaje-'+val.value] = valores['mensaje-'+val.value] ? valores['mensaje-'+val.value] : 'Cancelado'; 
          }else{
            valores['mensaje-'+val.value] = '';
          }
          const pos = lista.findIndex(f=> typeof valores['mensaje-'+val.value]==='string' && f.titulo===valores['mensaje-'+val.value].toUpperCase())
          if (valores['mensaje-'+val.value]===''){
            valores['mensaje-'+val.value]=lista[0];
          }else if (pos!==-1){
            valores['mensaje-'+val.value]=lista[pos];
          }
          return val
        })
        
        const nuevos =  await genera_formulario({valores, campos: Form_todos('Form_Mensualidad') });
        const formulario ={
            ...nuevos,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'xl',
            Titulo:`${valores.nombres} ${valores.apellidos} ${valores.grado}`,
            Cuerpo: <Formulario  {...formulario} Agregar={false} config={Ver_Valores().config}/> ,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    
    const promedio = (valores)=>{
        let resultado =0;
        if (valores.length===0){
            return 0;
        }
        valores.map(val=>{
            resultado+=val;
            return val
        })
        resultado = valores.length !==0  ? resultado/valores.length : 0;
        if (isNaN(resultado)){
            return 0
        }
        return resultado
    }
    const Ver_data = async(periodo, grado, momento, formulario=null)=>{
        cambiarState({esperar:true});
        var y = new Date().getFullYear();
        var y1= Number(y)-1;
        let actual = `${y1}-${y}`;
        periodo=periodo ? periodo.periodo : actual;
        const seccion = grado.seccion;
        grado=grado.grado 
        const titulos = await Titulos_todos(`Titulos_NotasP`, Config);
        Formularios= formulario!==null ? formulario : Formularios;
        
        // if (grado===undefined){
        //     cambiarState({esperar:false, grado, seccion, titulos, formulario, datos:[]});
        //     return
        // }
        const resultado = await conexiones.Leer_C(['uecla_NNota','uecla_Asigntura'],{
            uecla_NNota:{"valores.periodo":periodo,"valores.grado":grado, "valores.seccion":seccion},
            uecla_Asignatura:{}
        });
        
        if (resultado.Respuesta==='Ok'){
            let datos = Notas_Promedio(resultado.datos.uecla_Asignatura, resultado.datos.uecla_NNota);
            // let asignaturas = resultado.datos.uecla_Asignatura.map(val=> val.valores).sort((a,b)=> Number(a.item)>Number(b.item) ? 1 : -1);
            // asignaturas= asignaturas.filter(val=> 
            //         (val.grado.value==='1er' && Number(val.item)<8)
            //         || (val.grado.value==='2do' && Number(val.item)<8)
            //         || (val.grado.value==='3er' && Number(val.item)<9)
            //         || (val.grado.value==='4to' && Number(val.item)<10)
            //         || (val.grado.value==='5to' && Number(val.item)<11)
            // )
            
            // let datos=resultado.datos.uecla_NNota.map(val=>{
            //     //para calcular pormedio
            //     let pnotas = {lapso1:[],lapso2:[],lapso3:[]}
            //     let notasc = {}
            //     Object.keys(val.valores).map(d=>{
            //         if ((d.indexOf('1lapso')!==-1 || d.indexOf('2lapso')!==-1 || d.indexOf('3lapso')!==-1)){
            //             const pos = asignaturas.findIndex(f=> f._id===d.split('-')[1]);
            //             if (pos!==-1){
            //                 // notasc[d]=  val.valores[d];
            //                 notasc[`${d.split('-')[0]}-${d.split('-')[1]}`]= val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-consejo`] 
            //                     ?  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-consejo`]
            //                     :  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-art112`] 
            //                     ?  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}-art112`] 
            //                     :  val.valores[`${d.split('-')[0]}-${d.split('-')[1]}`];
            //                 // notasc[`asignatura_${d.split('-')[1]}_nombre`]=val.valores[`asignatura_${d.split('-')[1]}_nombre`]
            //             }
            //         }
            //         return d;
            //     })
            //     Object.keys(notasc).map(d=>{
            //         if (d.indexOf('1lapso')!==-1){
            //             pnotas.lapso1= [...pnotas.lapso1, Number(notasc[d])];
            //         }else if (d.indexOf('2lapso')!==-1){
            //             pnotas.lapso2= [...pnotas.lapso2, Number(notasc[d])];
            //         }else if (d.indexOf('3lapso')!==-1){
            //             pnotas.lapso3= [...pnotas.lapso3, Number(notasc[d])];
            //         }
            //         return d
            //     })
            //     const lapso1= promedio(pnotas.lapso1);
            //     const lapso2= promedio(pnotas.lapso2);
            //     const lapso3= promedio(pnotas.lapso3);
            //     const promediol = lapso1!==0 && lapso2!==0 && lapso3!==0 
            //         ? (lapso1+lapso2+lapso3)/3
            //         : lapso1!==0 && lapso2!==0 && lapso3===0 
            //         ? (lapso1+lapso2)/2
            //         : lapso1!==0 && lapso2===0 && lapso3===0 
            //         ? lapso1
            //         : 0
            //     return {...val.valores, lapso1, lapso2,lapso3, promediol}
            // });
            datos= momento.value==='promedio' 
                    ?   datos.sort((a,b)=> a.promediol>b.promediol ? -1 : 1)
                    :   momento.value==='momento1'
                    ?   datos.sort((a,b)=> a.lapso1>b.lapso1 ? -1 : 1)
                    :   momento.value==='momento2'
                    ?   datos.sort((a,b)=> a.lapso2>b.lapso2 ? -1 : 1)
                    :   datos.sort((a,b)=> a.lapso3>b.lapso3 ? -1 : 1)
            
            cambiarState({datos, formulario, esperar:false, grado, seccion, titulos})
        }else{
            cambiarState({esperar:false, grado, seccion, formulario,titulos, datos:[]});
        }

    }
    const Cambio_dato = async (valores)=>{
        const {periodo, grado, momento}=valores.resultados;
        if (periodo===null || grado===null) return
        let formulario = state.formulario ? state.formulario : Formularios;
        formulario.titulos[0].value.grado.value=grado
        formulario.titulos[0].value.periodo.value=periodo
        formulario.titulos[0].value.momento.value=momento
        Formularios= formulario
        Ver_data(periodo, grado, momento, formulario)
        
    }
    const Inicio = async() =>{
        // cambiarState({esperar:true});
        let formulario = await genera_formulario({valores:{}, campos: Form_todos('Form_filtro_solvencias') })
        const periodos =formulario.titulos[0].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario.titulos[0].value.periodo.value= periodos[0];
        formulario.titulos[0].value.periodo.lista= periodos;
        formulario.titulos[0].value.periodo.onChange= Cambio_dato;
        
        let grados =  Ver_Valores().config.Listas.lista_colegio_grado;
        let seccion = Ver_Valores().config.Listas.lista_colegio_seccion;

        let ngrados=[{titulo:`Sin grado`, grado:undefined, seccion: undefined}];
        grados.map(val=>{
            let dato= seccion.map(sec=>{
                return {...val, titulo:`${val.titulo} ${sec.titulo}`, grado:val.titulo, seccion: sec.titulo}
            })
            ngrados=[...ngrados, ...dato]
            return dato
        })
        
        formulario.titulos[0].value.grado.value= ngrados[0];
        formulario.titulos[0].value.grado.lista=ngrados
        formulario.titulos[0].value.grado.onChange= Cambio_dato;
        let momentos = [
            {_id:0, titulo:'Total', value:'promedio'},
            {_id:1, titulo:'1er Momento', value:'momento1'},
            {_id:2, titulo:'2do Momento', value:'momento2'},
            {_id:3, titulo:'3er Momento', value:'momento3'},
        ]
        formulario.titulos[0].value.momento = {...formulario.titulos[0].value.grado};
        formulario.titulos[0].value.momento.value = momentos[0];
        formulario.titulos[0].value.momento.lista = momentos;
        formulario.titulos[0].value.momento.nombre='momento';
        formulario.titulos[0].value.momento.key='momento';
        formulario.titulos[0].value.momento.name='momento';
        formulario.titulos[0].value.momento.label='Promedio';
        formulario.titulos[0].value.momento.title='Promedio';
        formulario.titulos[0].value.momento.onChange = Cambio_dato
        
        cambiarState({formulario})
        Formularios= formulario;
        Ver_data(periodos[0], ngrados[0], momentos[0], formulario)
        // Ver_data()
    }
   
   
    const Exportar = ()=>{
            let formulario = state.formulario ? state.formulario : Formularios;
            let periodo= formulario.titulos[0].value.periodo.value;
            const nuevo = state.datos.map((val1,i)=>{
                let val={};
                Object.keys(val1).map(key=>{
                    val[key] = val1[key]==='No Cancelado' ? '' : val1[key];
                    return
                })
                return {
                    ID:i+1,
                    CEDULA:val.cedula,
                    NOMBRES:val.nombres,
                    APELLIDOS:val.apellidos,
                    GRADO:val.grado,
                    SECCION:val.seccion,
                    "1ER MOMENTO": val.lapso1.toFixed(2),
                    "2DO MOMENTO": val.lapso2.toFixed(2),
                    "3ER MOMENTO": val.lapso3.toFixed(2),
                    PROMEDIO: val.promediol.toFixed(2)
                    
                }
            })
            
            AExcell(nuevo,`Promedios ${periodo.periodo} ${state.grado ? state.grado : 'seccion'} ${state.seccion ? state.seccion : ''}`, `Promedios ${periodo.periodo} ${state.grado ? state.grado : 'uecla'} ${state.seccion ? state.seccion : ''}.xlsx`);
    }

    useEffect(()=>{
        
        Inicio();
        return ()=>{}
    }, [])

    
    const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    
    return !state.titulos ? <Esperar open={true} Config={Config}/> : (
        <div>
            {/* <div style={{width:'100%', overflowX:'auto', backgroundColor:'#0f0',   }}> */}
                <Tabla  Titulo={"Notas"}
                        alto={Ver_Valores().tipo==='Web' ? window.innerHeight * 0.7 : window.innerHeight * 0.73}
                        Config={Config ? Config : Ver_Valores().config}
                        titulos={state.titulos}
                        table={'uecla_NNota'}
                        cantidad={state.cantidad ? state.cantidad : null}
                        cargacompleta={Actualizar_data}
                        datos={state.datos}
                        Accion={Abrir}
                        cargaporparte={false }
                        Noactualizar
                        sinpaginacion={true}
                        acciones={
                            state.formulario ?
                            <Stack direction="row" spacing={0.5}>
                                <IconButton color={'primary'} title={'Refrescar valores de Solvencias'} onClick={Refrescar}>
                                    <AutorenewIcon style={color}/>
                                </IconButton>
                                <IconButton color={'primary'} title={'Exportar a excell'} onClick={Exportar}>
                                    <Icon style={color}>assignment</Icon>
                                </IconButton>
                                <div style={{width:window.innerWidth * 0.35}}>
                                    {state.formulario   ?   <Formulario {...state.formulario} 
                                                                    config={{
                                                                            Estilos:{
                                                                                Input_label:{color:'#fff'},
                                                                                Input_fondo:'#fff',
                                                                                Input_input:{color:'#fff'}
                                                                            }
                                                                    }}
                                                            /> 
                                                        : null
                                    }
                                </div>
                            </Stack>
                            : null
                        }
                        
                />
                
                <Dialogo  {...dialogo} config={Ver_Valores().config}/>
                <Esperar open={state.esperar}/>
                
            {/* </div> */}
        </div>
    )
}

export default Notast;