import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TablaMultiple from '../../../../componentes/herramientas/tabla/tabla_multiple';
import { genera_formulario, crear_campos, Form_todos, Ver_Valores, conexiones, nuevo_Valore, Titulos_todos } from '../../../../constantes' 

import Formulario from '../../../../componentes/herramientas/formulario';
import Dialogo from '../../../../componentes/herramientas/dialogo';

import Cargando from '../../../../componentes/esperar/cargar';

import { Condicion_Estudiante, Condicion_Representante } from '../../funciones';

import moment from 'moment';


import { Icon } from '@mui/material';
import Scrollbars from '../../../../componentes/herramientas/scrolbars';

import { Formato_Boleta, Notas_Promedio, TitulosNotas, Calculo_notas, Nota_Entera } from './comunes';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


  
export default function Nota(props) {
    const [state,setState] = useState({buscar:'', dialogo:{open:false}, esperar:false})
    const {Config}= props
    const CambioState = (nuevo)=>{
        setState({...state, ...nuevo})
    }
    const Inicio = async()=>{
        
        const result = await conexiones.Leer_C(['uecla_Asigntura'],{
            uecla_Asignatura:{}
        })
        
        if (result.Respuesta==='Ok'){
            let asignaturas = result.datos.uecla_Asignatura.map(val=> val.valores).sort((a,b)=> Number(a.item)>Number(b.item) ? 1 : -1);
            CambioState({asignaturas});
        }
    }
    const Condiciones = async(campo, datos) =>{
        let {valores}= datos
        switch (campo) {
            case 'uecla_Estudiante':{
                valores= await Condicion_Estudiante(datos);
                return valores
            }
            case 'uecla_Representante':{
                valores = await Condicion_Representante(datos);
                return valores
            }
            default:
                return valores;
        }
    
    }
    const Guardar=async(valores)=>{

        let nota = Calculo_notas(valores);
        
        await conexiones.Guardar({_id:nota._id, valores:nota, multiples_valores:true},'uecla_NNota');
        
        Seleccion({_id:state.estudiante._id , valores:state.estudiante})
    }

    const FormatoBoletas = async(val)=>{
        let datos ={
            periodo: val.periodo, tipo: 'seccion',
            grado:val.grado, seccion:val.seccion,
        }
        const resp= await conexiones.NTitulos(datos);
        
        let est_titulos = await TitulosNotas(resp.titulosn, {value:"seccion"});

        await Formato_Boleta([val], est_titulos, datos);
    }
    const Buscar = async()=>{
        if (state.buscar==='') return
        CambioState({ esperar:true});
        let result = await conexiones.Leer_C(['uecla_Estudiante'],{
            uecla_Estudiante:{"valores.cedula":state.buscar}
        });
        if (result.Respuesta==='Ok'){
            let estud = result.datos.uecla_Estudiante.length!==0 ? result.datos.uecla_Estudiante[0] : null;
            if (estud!==null){
                result = await conexiones.Leer_C(['uecla_NNota'],{
                    uecla_NNota:{"valores._id_estudiante":estud._id}
                });
                let notas = Notas_Promedio(state.asignaturas, result.datos.uecla_NNota);//result.datos.uecla_NNota.map(val=>val.valores)
                
                CambioState({ estudiante:estud.valores, notas, esperar:false})
                return
            }
        }
        CambioState({ esperar:false})
        
    }
    const Seleccion= async(estud)=>{
        let result = await conexiones.Leer_C(['uecla_NNota'],{
            uecla_NNota:{"valores._id_estudiante":estud._id}
        });
        let notas = Notas_Promedio(state.asignaturas, result.datos.uecla_NNota);//result.datos.uecla_NNota.map(val=>val.valores)
        
        CambioState({ estudiante:estud.valores, notas, esperar:false, dialogo:{open:false}})
    }
    const BuscarTotal = async()=>{
        const titulos = await Titulos_todos(`Titulos_Estudiante`, Ver_Valores().config)
        CambioState({dialogo:{...state.dialogo,
            open:true,
            tam:'md',
            Titulo:`Estudiantes`,
            Cuerpo:
                        <TablaMultiple
                            alto={window.innerHeight * 0.62}
                            Config={Ver_Valores().config}
                            multiples_valores={true}
                            Agregar_mas={false}
                            Columnas={2} 
                            Form_origen = {Form_todos(`Form_Estudiante`, Ver_Valores().config)}
                            Titulo_tabla={'Estudiantes'}
                            Table = {'uecla_Estudiante'}
                            Eliminar_props={(dato)=>{
                                return `Desea eliminar al estudiante ${dato.nombres} ${dato.apellidos}`
                            }}
                            Titulo_dialogo ={"Ess"}
                            Titulos_tabla = {titulos}
                            cargaporparte = {true}
                            sinpaginacion = {false}
                            
                            Tam_dialogo={'xl'}
                            Seleccion={Seleccion}
                            
                        />
                   ,
            Cerrar: ()=>{
                CambioState({dialogo:{open:false}})
                
            },
        }})
    }
    const Detalles= async(valor, notas)=>{
        CambioState({esperar:true})
        const data = [valor.lapso, valor._id];
        let docente='';
        let asignatura ='';
        const lapso = valor.lapso==='1lapso' ? '1er Lapso' : valor.lapso==='2lapso' ? '2do Lapso' : '3er Lapso';
        let dat_asig = {};
        Object.keys(notas).map(til=>{
            if ((til.indexOf(valor.lapso)!==-1 && til.indexOf(valor._id)!==-1) || til.indexOf(`asignatura_${valor._id}`)!==-1 || notas[til]===valor._id){
                dat_asig[til]=notas[til];
                docente = til.indexOf(`asignatura_${valor._id}`)!==-1 ? notas[`asignatura_${valor._id}_docente`] : docente;
                asignatura = til.indexOf(`asignatura_${valor._id}`)!==-1 ? notas[`asignatura_${valor._id}_nombre`] : asignatura;
                if(notas[til]===valor._id && til.split('-')[0]==='nota' && notas[`${til.split('-')[0]}-${til.split('-')[1]}-lapso`]===lapso){
                    let nota = til.split('-');
                    dat_asig[`${nota[0]}-${nota[1]}`]=notas[`${nota[0]}-${nota[1]}`];
                    dat_asig[`${nota[0]}-${nota[1]}-nombre`]=notas[`${nota[0]}-${nota[1]}-nombre`];
                    dat_asig[`${nota[0]}-${nota[1]}-lapso`]=notas[`${nota[0]}-${nota[1]}-lapso`];
                }
            }
            return til
        })
        
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{asignatura}</Typography>    
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{docente}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:130, width:130},
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{notas.grado} {notas.seccion}</Typography>
                                <Typography fontWeight={'bold'}>{lapso}</Typography>
                            </div>,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'label1',
                    title: ``,
                    formato:(datos)=>{
                        let calcula = {};
                        Object.keys(dat_asig).map(val=>{
                            calcula[val]= datos[val]
                            return
                        })
                        calcula= Calculo_notas(calcula);
                        
                        return  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                                    <Typography fontWeight={'bold'}>{lapso}</Typography>
                                    <Typography fontWeight={'bold'}>Nota: {calcula[`${data[0]}-${data[1]}`] ? calcula[`${data[0]}-${data[1]}`].toFixed(2) : '?'}</Typography>
                                </div>
                    },
                    // variant:'h6',
                    solo:false
                },
                {
                    label:  '',
                    name:'titulo4',
                    nombre:'titulo4',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:false
                },
                {
                    label:  '',
                    name:'titulo5',
                    nombre:'titulo5',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                }
                
            ]
        }
        let rasgos = [
            {
                label: `Rasgo`,
                name:`${data[0]}-${data[1]}-rasgos`,
                nombre:`${data[0]}-${data[1]}-rasgos`,
                tipo:'notas',
                disabled: true,
                title: `Rasgo`,
                type:'number',
                modificar:'Maximo_minimoR',
                sinicono:true,
            },
            {
                label: `Art112`,
                name:`${data[0]}-${data[1]}-art112`,
                nombre:`${data[0]}-${data[1]}-art112`,
                tipo:'notas',
                title: `Art112`,
                type:'number',
                modificar:'Maximo_minimo',
                sinicono:true,
            },
            {
                label: `Consejo`,
                name:`${data[0]}-${data[1]}-consejo`,
                nombre:`${data[0]}-${data[1]}-consejo`,
                tipo:'notas',
                title: `Consejo`,
                type:'number',
                modificar:'Maximo_minimo',
                sinicono:true,
            }
        ];

        //==============================================
        const nuevodatos = {
            periodo:notas.periodo, 
            grado:notas.grado,
            seccion:notas.seccion, 
            tipo:'docente', 
            asignatura:{
                asignatura:dat_asig[`asignatura_${data[1]}_nombre`],
                asignatura:dat_asig[`asignatura_${data[1]}_titulo`],
                _id:data[1],
            },
            docente:{
                _id:dat_asig[`asignatura_${data[1]}_id_docente`],

            }
        }
                    
        let nest_titulos = await Titulos_todos(`Titulos_Notas`, Config);
        nest_titulos = nest_titulos.map(val=>{
            return {...val, rowSpan:2}
        })
        const resultado = await conexiones.NNotas(nuevodatos);
    
        let fila1 = resultado.titulosn.datos[0].map(asig=>{
            return {
                default : "",
                field : `${asig.field}`,
                formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                orden: false, 
                tipo : "",
                title : asig.titulo,
                type : "number",
                lapso:asig.lapso,
                vertical:false,
                colSpan:7,                  

            }
        })
        
        const fila2 = resultado.titulosn.datos[1].map(asig=>{
            const definitiva = (asig.field.indexOf("1lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1)
                                && (asig.field.indexOf("-art112")===-1  && asig.field.indexOf("-rasgos")===-1)
            return {
                default : "",
                field : `${asig.field}`,
                formato : (dato)=> dato[asig.field] ? definitiva ? Nota_Entera(Number(dato[asig.field])) :Number(dato[asig.field]).toFixed(2) : '',
                orden: false, 
                tipo : "",
                title : asig.titulo,
                type : "number",
                lapso:asig.lapso,
                vertical:asig.vertical ? asig.vertical : false, 

            }
        })
        
        fila1 =[...nest_titulos, ...fila1];
        nest_titulos={filas:2 ,datos:[fila1,fila2], values:[...nest_titulos,...fila2]};
        //;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
        let agregarI=[];
        Object.keys(dat_asig).map(val=>{
            if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1
                && val.indexOf('lapso')===-1
                ){
                const pos = nest_titulos.values.findIndex(f=> `${f.field}`===val);
                if ((pos!==-1) ){
                    agregarI=[...agregarI,{
                        name: `filename-${val}`,
                        nombre: `filename-${val}`,
                        tipo: "Imagen",
                        label: `Imagen ${nest_titulos.values[pos].title}`,
                        title: `Imagen ${nest_titulos.values[pos].title}`,
                    }];
                    formato.value =[...formato.value,
                        {
                            label: `${nest_titulos.values[pos].title}`,
                            name:val,
                            nombre:val,
                            tipo:'notas',
                            title: `${nest_titulos.values[pos].title}`,
                            type:'number',
                            modificar:'Maximo_minimo',
                            sinicono:true,
                        }
                    ]
                }
                
            }
            return val
        })
        agregarI =  agregarI.length===0
                    ? []
                    : [...agregarI,
                        ...rasgos
        ]
        formato.columna= agregarI.length!==0 ? agregarI.length : 2;
        formato.value =[...formato.value,
            ...agregarI.length===0 
                    ? [
                        {
                            label: `SIN NOTAS`,
                            name:'titulo133',
                            nombre:'titulo133',
                            tipo:'mensaje1',
                            title: ``,
                            solo:true
                        }
                    ] 
                    :rasgos, 
        ];
        let formulario = await genera_formulario({ valores:{...notas}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                name:'guardar', label:'Guardar', title:'Guardar ',
                variant:"contained", color:"success", icono:<CheckIcon/>,
                onClick: Guardar, validar:'true', 
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                name:'cancelar', label:'Cancelar', title:'Cancelar',
                variant:"contained",  icono:<CancelIcon/>,
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                onClick: ()=>CambioState({dialogo:{open:false}})
                },
            ]
        }
        CambioState({esperar:false,dialogo:{
            open: true,
            //tam:'l',
            Titulo:`NOTAS  DE  "${notas.apellidos} ${notas.nombres}"`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>CambioState({dialogo:{open:false}})
        }})
    }
    const NotasLapso = (notas)=>{
        let nnotas ={} 
        let asig = state.asignaturas.filter(f=> f.grado.titulo===notas.grado)
        // Object.keys(notas).map(f=>{
        //     if((f.indexOf('1lapso')!==-1 || f.indexOf('2lapso')!==-1 || f.indexOf('3lapso')!==-1) && f.indexOf('-rasgos')===-1){
        //         nnotas[f]= notas[f];
                
        //     }
        //     return f
        // })
        
        return asig.map(valor=>{
            
            const color=(valor.grado.value==='1er' && Number(valor.item)<8)
            || (valor.grado.value==='2do' && Number(valor.item)<8)
            || (valor.grado.value==='3er' && Number(valor.item)<9)
            || (valor.grado.value==='4to' && Number(valor.item)<10)
            || (valor.grado.value==='5to' && Number(valor.item)<11)
            ? '' :'#fca174';
            return  <Stack key={valor._id}
                        direction="row"
                        sx={{pl:1}}
                    >
                        <Typography variant="subtitle2" sx={{color ,width:'40%', textAlign:'left', fontWeight:'bold'}} noWrap>
                            {valor.asignatura}  
                        </Typography>
                        <Typography variant="subtitle2" sx={{color, width:'15%', textAlign:'right', cursor:'pointer'}} onClick={()=>Detalles({...valor, lapso:'1lapso'},notas)}>
                            {notas[`1lapso-${valor._id}-consejo`] 
                                ?   Number(notas[`1lapso-${valor._id}-consejo`]).toFixed(2) 
                                :  notas[`1lapso-${valor._id}-art112`] 
                                ?   Number(notas[`1lapso-${valor._id}-art112`]).toFixed(2) 
                                :   notas[`1lapso-${valor._id}`] 
                                ?   Number(notas[`${'1lapso'}-${valor._id}`]).toFixed(2) : ''
                            }
                        </Typography>
                        <Typography variant="subtitle2" sx={{color, width:'15%', textAlign:'right', cursor:'pointer'}} onClick={()=>Detalles({...valor, lapso:'2lapso'},notas)}>
                            {notas[`2lapso-${valor._id}-consejo`] 
                                ?   Number(notas[`2lapso-${valor._id}-consejo`]).toFixed(2) 
                                :  notas[`2lapso-${valor._id}-art112`] 
                                ?   Number(notas[`2lapso-${valor._id}-art112`]).toFixed(2) 
                                :   notas[`2lapso-${valor._id}`] 
                                ?   Number(notas[`${'2lapso'}-${valor._id}`]).toFixed(2) : ''
                            }
                        </Typography>
                        <Typography variant="subtitle2" sx={{color, width:'15%', textAlign:'right', cursor:'pointer'}} onClick={()=>Detalles({...valor, lapso:'3lapso'},notas)}>
                            {notas[`3lapso-${valor._id}-consejo`] 
                                ?   Number(notas[`3lapso-${valor._id}-consejo`]).toFixed(2) 
                                :  notas[`3lapso-${valor._id}-art112`] 
                                ?   Number(notas[`3lapso-${valor._id}-art112`]).toFixed(2) 
                                :   notas[`3lapso-${valor._id}`] 
                                ?   Number(notas[`${'3lapso'}-${valor._id}`]).toFixed(2) : ''
                            }
                        </Typography>
                        <Typography variant="subtitle2" sx={{color, width:'15%', textAlign:'right', cursor:'pointer'}} onClick={()=>Detalles({...valor, lapso:'3lapso'},notas)}>
                            {notas[`${valor._id}-def`] 
                                ?   Nota_Entera(notas[`${valor._id}-def`])
                                :  ''
                                
                            }
                        </Typography>
                    </Stack>
        })
        // return Object.keys(nnotas).map(valor=>{
        //     const asig = valor.split('-');
        //     if (nnotas[`${valor}-consejo`]){
        //         return null
        //     }
        //     return <Stack key={valor}
        //         direction="row"
        //         sx={{pl:1}}
        //     >
        //         <Typography variant="subtitle2" sx={{width:'80%', textAlign:'left', fontWeight:'bold'}} noWrap>
        //             {notas[`asignatura_${asig[1]}_nombre`]}  
        //         </Typography>
        //         <Typography variant="subtitle2" sx={{width:'20%', textAlign:'right'}}>
        //             {Number(nnotas[valor]).toFixed(2)}
        //         </Typography>
              
        //     </Stack>
        // })
    }
    const NuevaNota=async()=>{
        console.log(state.estudiante)
        let formulario = await genera_formulario({valores:{}, campos: Form_todos('Form_Notas_anterior') })
        const periodos =formulario.titulos[0].value.periodo.lista.sort((a,b) => a.periodo> b.periodo ? -1 : 1)
        formulario.titulos[0].value.periodo.value= periodos[1];
        formulario.titulos[0].value.periodo.lista= periodos;
        formulario.titulos[0].value.grado.value=formulario.titulos[0].value.grado.lista[state.estudiante.grado._id>0 ? state.estudiante.grado._id-1 : 0]
        formulario.titulos[0].value.seccion.value = state.estudiante.seccion;
        console.log(formulario.titulos[0].value.seccion)
        formulario={
            ...formulario,
            botones:[
                {
                name:'guardar', label:'Guardar', title:'Guardar ',
                variant:"contained", color:"success", icono:<CheckIcon/>,
                onClick: Guardar, validar:'true', disabled: state.estudiante.grado._id===0,
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Aceptar : {}},
                },
                {
                name:'cancelar', label:'Cancelar', title:'Cancelar',
                variant:"contained",  icono:<CancelIcon/>,
                sx:{...Ver_Valores().config.Estilos.Botones ? Ver_Valores().config.Estilos.Botones.Cancelar : {}},
                onClick: ()=>CambioState({dialogo:{open:false}})
                },
            ]
        }
        CambioState({dialogo:{
            open: true,
            //tam:'l',
            Titulo:`NUEVA NOTA  DE  "${state.estudiante.apellidos} ${state.estudiante.nombres}"`,
            Cuerpo: state.estudiante.grado._id===0 ? <div>esta en 1er ano no tiene notas anterior en esta institucion</div>:<Formulario {...formulario}/>,
            Cerrar: ()=>CambioState({dialogo:{open:false}})
        }})
    }
    useEffect(()=>{
        Inicio()
    },[])
    const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    return (
        <Box sx={{ flexGrow: 1, position:'relative' }}>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <Item elevation={3} >
                        <Grid container spacing={0.5}>
                            <Grid item xs={6}>
                                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Cedula del Estudiante"
                                        title={'Cedula del estudiante que desea ver notas'}
                                        onChange={(value)=>CambioState({buscar:value.target.value})}
                                        
                                        onKeyPress={(event) =>{
                                            if (event.key==='Enter')
                                              Buscar()
                                        }}
                                    />
                                    <IconButton sx={{ p: '10px' }} title={'Busqueda por cedula'} onClick={Buscar}                                                
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton sx={{ p: '10px' }} title={'Busqueda completa'} onClick={BuscarTotal}                                                
                                    >
                                        <Icon>person_search</Icon>
                                    </IconButton>
                                    {/* {state.notas
                                        ?   <IconButton sx={{ p: '10px' }} title={'Agregar nota de otro periodo'} onClick={NuevaNota}>
                                                <Icon sx={{color:'#0ac40a'}}>post_add</Icon>
                                            </IconButton>
                                        :   null
                                    } */}
                                </Paper>
                            </Grid> 
                            <Grid item xs={8}>
                                
                            </Grid>       
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item sx={{height:'79vh'}}>
                        <Scrollbars>
                            {
                                state.notas
                                    ?   <Grid container spacing={2}
                                                sx={{
                                                    // justifyContent: "center",
                                                    alignItems: "flex-end",
                                                }}
                                        >
                                            <Grid xs={2} >
                                                <Item sx={{ m:1}}>
                                                    <Avatar alt={state.estudiante.apellidos} src={state.estudiante.foto} sx={{ width: 120, height: 120 }}/>
                                                </Item>
                                            </Grid>
                                            <Grid xs={7}>
                                                <Item sx={{ m:1}}>
                                                    <Typography variant="h6" gutterBottom sx={{fontWeight:'bold'}}>
                                                        {`${state.estudiante.nombres} ${state.estudiante.apellidos}`}
                                                    </Typography>
                                                </Item>
                                            </Grid>
                                            <Grid xs={3}>
                                                <Item sx={{ m:1}}>
                                                    <Typography variant="h6" gutterBottom sx={{fontWeight:'bold'}}>
                                                        {`${state.estudiante.grado ? state.estudiante.grado.titulo : ''} ${state.estudiante.seccion ? state.estudiante.seccion.titulo : ''}`}
                                                    </Typography>
                                                </Item>
                                            </Grid>
                                            
                                            {state.notas.map(val=>
                                                <>
                                                    <Grid xs={6}>
                                                        <Item sx={{ m:1}}>
                                                            <Typography variant="h6" gutterBottom sx={{fontWeight:'bold'}}>
                                                            {val.periodo}
                                                            <Stack 
                                                                    direction="row"
                                                                    sx={{pl:1}}
                                                            >
                                                                <IconButton color={'primary'} title={'Boleta'} onClick={()=>FormatoBoletas(val)}>
                                                                    <Icon style={{color:'#000'}}>switch_account</Icon>
                                                                </IconButton>
                                                            </Stack>
                                                            </Typography>
                                                        </Item>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Item sx={{ m:1}}>
                                                            <Typography variant="h6" gutterBottom sx={{fontWeight:'bold'}}>
                                                            {val.grado} (PROMEDIO: {Number(val.promediol).toFixed(2)} ptos)
                                                            </Typography>
                                                        </Item>
                                                    </Grid>
                                                    <Grid container spacing={1}
                                                            sx={{
                                                                // justifyContent: "center",
                                                                
                                                            }}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Item >
                                                                <Stack 
                                                                    direction="row"
                                                                    sx={{pl:1}}
                                                                >
                                                                    
                                                                    <Typography variant="subtitle" sx={{width:'40%', textAlign:'center', fontWeight:'bold'}} noWrap>
                                                                        ASIGNATURA
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{width:'15%', textAlign:'center', fontWeight:'bold'}}>
                                                                        1ER MOMENTO
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{width:'15%', textAlign:'center', fontWeight:'bold'}}>
                                                                        2DO MOMENTO
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{width:'15%', textAlign:'center', fontWeight:'bold'}}>
                                                                        3ER MOMENTO
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{width:'15%', textAlign:'center', fontWeight:'bold'}}>
                                                                        DEFINITIVA
                                                                    </Typography>
                                                                </Stack>
                                                                {NotasLapso(val)}
                                                                <Stack 
                                                                    direction="row"
                                                                    sx={{pl:1}}
                                                                >
                                                                    
                                                                    <Typography variant="subtitle" sx={{color:'#2c932f',width:'40%', textAlign:'left', fontWeight:'bold'}} noWrap>
                                                                        PROMEDIO
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{color:'#2c932f',width:'15%', textAlign:'right', fontWeight:'bold'}}>
                                                                        {Number(val.lapso1).toFixed(2)}
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{color:'#2c932f',width:'15%', textAlign:'right', fontWeight:'bold'}}>
                                                                        {Number(val.lapso2).toFixed(2)}
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{color:'#2c932f', width:'15%', textAlign:'right', fontWeight:'bold'}}>
                                                                        {Number(val.lapso3).toFixed(2)}
                                                                    </Typography>
                                                                    <Typography variant="subtitle" sx={{color:'#2c932f', width:'15%', textAlign:'right', fontWeight:'bold'}}>
                                                                        {Number(val.definitiva).toFixed(2)}
                                                                    </Typography>
                                                                </Stack>
                                                            </Item>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </>
                                            )}
                                            
                                        </Grid>
                                        :   null
                        
                            }
                        </Scrollbars>
                    </Item>
                </Grid>
                
            </Grid>
            <Dialogo  {...state.dialogo} config={Ver_Valores().config}/>
            <Cargando open={state.esperar} Config={Config}/>
        </Box>
    )
}