import React, {useState, useEffect} from 'react';
import TablaMultiple from '../../../componentes/herramientas/tabla/tabla_multiple';
import Tabla from '../../../componentes/herramientas/tabla';
import { conexiones, Ver_Valores, genera_formulario} from '../../../constantes';
import Dialogo from '../../../componentes/herramientas/dialogo';
import Formulario from '../../../componentes/herramientas/formulario';
import { Form_todos, Titulos_todos } from '../../../constantes/formularios';
import Cargando from '../../../componentes/esperar/cargar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

function Evaluaciones (props) {
    const {Datos, Notas} = props;
    const [state, setState]= useState({esperar:true, dialogo:{open:false}});
    const {User} = Ver_Valores();
    const permiso = [0,1].indexOf(Number(User.categoria))!==-1 
                        || (User.permisos && User.permisos.indexOf('Nota_modificar')!==-1);
    const cambiarState = (nuevostate)=>{
        setState({...state, ...nuevostate, cambio:true})
    }
    const Actualizar_data = (valores)=>{
        // console.log(valores)
        // buscado=valores;
        // cambiarState({buscado:valores})
    }
    const Guardar=async(valores)=>{
        valores={...valores,
            periodo:Datos.periodo,
            docente:{
                _id:Datos.docente._id,
                cedula:Datos.docente.cedula,
                nombres:Datos.docente.nombres,
                apellidos:Datos.docente.apellidos,
            },
            asignatura:Datos.asignatura,
            grado:Datos.grado,
            seccion: Datos.seccion
        }
        delete valores.datos;
        
        const nuevos= await conexiones.Guardar({_id:valores._id, valores, multiples_valores:true},'uecla_Evaluacion');
        cambiarState({esperar:false, dialogo:{open:false}});
        Inicio();
        return nuevos;
    }
    const Eliminar = async(datos) =>{
        cambiarState({esperar:true, mensaje:'Eliminando...'})
        const nuevos = await conexiones.Eliminar(datos,['uecla_Evaluacion']);
        cambiarState({esperar:false, dialogo:{open:false}});
        Inicio();
        
            
    }
    const Condiciones = (campos, datos) =>{
        
        let {valores}= datos
        valores={...valores,
            periodo:Datos.periodo,
            docente:{
                _id:Datos.docente._id,
                cedula:Datos.docente.cedula,
                nombres:Datos.docente.nombres,
                apellidos:Datos.docente.apellidos,
            },
            asignatura:Datos.asignatura,
            grado:Datos.grado,
            seccion: Datos.seccion
        }
        return valores
    }

    const Inicio = async() =>{
        const titulos = await Titulos_todos(`Titulos_Evaluacion`, Config)
        
        let datos =[];
        const resp= await conexiones.Leer_C(['uecla_Evaluacion'],{
            uecla_Evaluacion:{$and:[
                {
                    'valores.periodo':Datos.periodo
                },
                {
                    'valores.docente._id':Datos.docente._id
                },
                {
                    'valores.grado':Datos.grado
                },
                {
                    'valores.seccion':Datos.seccion
                },
                {
                    'valores.asignatura._id':Datos.asignatura._id
                },
            ]}
        })
        if(resp.Respuesta==='Ok'){
            datos = resp.datos.uecla_Evaluacion
        }
        
        cambiarState({esperar:false, titulos, datos})
    }

    const Agregar = ()=>{
        Abrir({});
    }
    const Abrir=async(valor)=>{
        const {datos}= state;
        const Config= Ver_Valores().config
        let nuevos =  await genera_formulario({valores:{_id:valor._id, ...valor.valores, datos:datos.map(val=>val.valores)}, campos: Form_todos('Form_Evaluacion') });
        
        const cantidad= buscarNotas({_id:valor._id, ...valor.valores});
        const formulario ={
            ...nuevos,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                ...valor._id!==undefined  && permiso
                    ?
                        [{
                        name:'eliminar', label:'Eliminar', title:'Eliminar ',
                        variant:"contained", color:"secondary", icono:<DeleteIcon/>,
                        sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Eliminar : {}},
                        confirmar:'true', 
                        confirmar_mensaje:(dato)=>{
                            // console.log(dato)
                            // const cantidad= buscarNotas(dato);
                            return  (<div>
                                        {cantidad===0
                                            ?   null
                                            :   <div style={{color:'red'}}>
                                                    Existen ({cantidad}), estudantes con nota en "{dato.lapso.titulo} {dato.nombre}".
                                                </div>   
                                        }
                                        {/* <div>
                                            Antes de eliminar verifique que ningún estudiante posea notas en “{dato.nombre}”.
                                        </div>  */}
                                        <div>
                                            Desea eliminar Evaluación "{dato.lapso.titulo} {dato.nombre}"?
                                        </div>
                                    </div>)
                        },
                        
                        confirmar_campo: 'nombre',
                        onClick: Eliminar,
                        disabled: cantidad!==0,
                        title:cantidad!==0 ? `Existen (${cantidad}), estudantes con nota en " {valor.lapso.titulo} {valor.nombre}".` : ''
                        }]
                    :[],
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>cambiarState({dialogo:{open:false}})
                },
            ]
        }
        cambiarState({
            dialogo:{ 
            open: true,
            // tam:'l',
            Titulo:valor._id!==undefined ? `Evaluación` : `Nueva Evaluación`,
            Cuerpo: <Formulario  {...formulario} Agregar={false} config={Ver_Valores().config}/> ,
            Cerrar: ()=>cambiarState({dialogo:{open:false}}),
        }
        })
    }
    const Ordenar = (datos)=>{
        // console.log(datos, Datos.grado, Datos.seccion)
        let resultados= datos.filter(f=> {
           return  f.valores.periodo===Datos.periodo 
            && String(f.valores.docente._id)===String(Datos.docente._id)
            && f.valores.grado===Datos.grado 
            && f.valores.seccion===Datos.seccion
            && f.valores.asignatura._id===Datos.asignatura._id  
        }
        )
        return resultados
    }
    
    const buscarNotas=(evaluacion)=>{
        let cantidad=0;
        Notas.map(val=>{
            if (val[`nota-${evaluacion._id}`]){
                cantidad++;
            }
            return val
        })
        return cantidad
    }
    useEffect(()=>{
        Inicio()
    }, [props])
    const {Config}= props;
    const color =  Ver_Valores().config.Estilos.Input_icono_t ;
    return state.esperar ? <Cargando open={state.esperar} Config={Config}/> :(
        <div>
            <Tabla  Titulo={"Evaluaciones"}
                    alto={Ver_Valores().tipo==='Web' ? window.innerHeight * 0.7 : window.innerHeight * 0.73}
                    Config={Config ? Config : Ver_Valores().config}
                    titulos={state.titulos}
                    table={'uecla_Evaluacion'}
                    cantidad={state.cantidad ? state.cantidad : null}
                    cargacompleta={Actualizar_data}
                    datos={state.datos}
                    Accion={Abrir}
                    cargaporparte={false }
                    Noactualizar
                    sinpaginacion={true}
                    acciones={
                        <Stack
                            direction={ 'row' }
                            spacing={1}
                            justifyContent="left" alignItems="center"
                        >
                                <IconButton color={'primary'} title={`Refrescar Evaluaciones`} onClick={Inicio}>
                                    <AutorenewIcon style={color}/>
                                </IconButton>
                                <IconButton color={'primary'} title={`Agregrar nueva Evaluación`} onClick={Agregar}>
                                    <AddIcon style={color}/>
                                </IconButton> 
                            
                        </Stack>
                    }
                    
            />

            <Dialogo  {...state.dialogo} config={Ver_Valores().config}/>
            <Cargando open={state.esperar}/>
        </div>

            // <TablaMultiple
                
            //     Config={Config ? Config : Ver_Valores().config}
            //     multiples_valores={true}
            //     Agregar_mas={false}
            //     Condiciones={Condiciones}
            //     Columnas={2} 
            //     Form_origen = {Form_todos(`Form_Evaluacion`, Config)}
            //     Titulo_tabla={'Evaluaciones'}
            //     Table = {'uecla_Evaluacion'}
            //     Eliminar_props={(dato)=>{
            //         const cantidad= buscarNotas(dato);
            //         return <div>
            //                     {cantidad===0
            //                         ?   null
            //                         :   <div style={{color:'red'}}>
            //                                 Existen ({cantidad}), estudantes con nota en "{dato.nombre}".
            //                             </div>   
            //                     }
            //                     {/* <div>
            //                         Antes de eliminar verifique que ningún estudiante posea notas en “{dato.nombre}”.
            //                     </div>  */}
            //                     <div>
            //                         Desea eliminar Evaluación?
            //                     </div>
            //                 </div>
            //     }}
            //     Titulo_dialogo ={(dato)=> dato._id ? `Evaluación`: `Nueva Evaluación`}
            //     Titulos_tabla = {state.titulos}
            //     cargaporparte = {true}
            //     ordenar={Ordenar}
            //     Acciones1={props.Titulo ? props.Titulo : ''}
            //     sinpaginacion={true}
            //     PEliminar={permiso}
            // />
        
      
    )
}

export default Evaluaciones;