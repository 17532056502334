import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tabla from '../../../componentes/herramientas/tabla';
import Formulario from '../../../componentes/herramientas/formulario';
import { genera_formulario, conexiones, Form_todos, Ver_Valores, Titulos_todos, NotasExcell } from '../../../constantes';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import Cargando from '../../../componentes/esperar/cargar';
import Logo from '../../../imagenes/logo.png';
import { Typography } from '@mui/material';
import Dialogo from '../../../componentes/herramientas/dialogo';
import Evaluaciones from './evaluaciones';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Formato_Boleta, Nota_Entera, TitulosNotas, Calculo_notas } from './control de estudio/comunes';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
  backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));

const editable =true;
export default function Notas(props) {
    const {Config} = props;
    const [state, setState] = React.useState({cargando:false});
    const [dialogo, setDialogo]= React.useState({
        open:false,  
    });
    const [cargando, setCargando] = React.useState(true);
    const {User} = Ver_Valores();
    const permiso = [0,1].indexOf(Number(User.categoria))!==-1 
                        || (User.permisos && User.permisos.indexOf('Nota_modificar')!==-1);
    
    const cambio = (valores)=>{
        setState({...state,...valores})
    }

    const Seleccion = async(valores)=>{
        const {resultados}= valores;
        let pnotas = await conexiones.Leer_C(['uecla_PNota'],{
            uecla_PNota:{'valores.periodo.periodo':resultados.periodo.periodo}
        });
        let perlapso={
            periodo:resultados.periodo.periodo,
            '1lapso': false,
            '2lapso': false,
            '3lapso': false,
        } 
        if (pnotas.Respuesta==='Ok'){
            perlapso = pnotas.datos.uecla_PNota.length!==0 
                        ?   {
                                periodo: pnotas.datos.uecla_PNota[0].valores.periodo.periodo,
                                '1lapso': pnotas.datos.uecla_PNota[0].valores.lapso1,
                                '2lapso': pnotas.datos.uecla_PNota[0].valores.lapso2,
                                '3lapso': pnotas.datos.uecla_PNota[0].valores.lapso3
                            }
                        :   perlapso
        }
        
        if(resultados.tipo && resultados.periodo && resultados.lista && resultados.grado){
            setCargando({...valores, perlapso})
        }
        
        return valores
    }
    
    const Agregar = ()=>{
        const {datos, formulario,est_datos} = state;
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'md',
            Titulo:`Evaluaciones de ${datos.grado} ${datos.seccion}, periodo ${datos.periodo}`,
            Cuerpo: <Evaluaciones Titulo= {datos.asignatura.titulo} Datos={datos} Notas={est_datos}/>,
            Cerrar: ()=>{
                setDialogo({...dialogo,open:false});
                Seleccion({name:'tipo', resultados:formulario.datos})
            },
        })
    }

    const GuardarTodo=async()=>{
        const {formulario, est_datos, datos}= state;
        cambio({cargando:true});
        const lista = est_datos.filter(f=>f.local);
        // console.log(lista);
        for (var i=0; i<lista.length;i++){
            const nota = {...lista[i]};
            delete nota.local;
            delete nota._id_docente;
            await conexiones.Guardar({_id:nota._id, valores:nota, multiples_valores:true},'uecla_NNota');
            
        }
        Seleccion({name:'tipo', resultados:formulario.datos})
    }
    // const Calculo_notas = (valores)=>{
    //     let nota ={
    //         //asignatura: datos.asignatura,
    //         // docente: datos.docente
    //     };
    //     let notas= {};
        
    //     Object.keys(valores).map(val=>{
            
    //         if (val.indexOf('Error-')===-1 && valores[val]!=='' && val!=='id' 
    //         ){
    //             nota[val]=valores[val];
    //             const notad = val.split('-');
    //             if (val.indexOf('nota-')!==-1 && notad.length===2 ){
    //                 const lapso = valores[`${val}-lapso`] === '1er Lapso' ? '1lapso' : valores[`${val}-lapso`] === '2do Lapso' ? '2lapso' : '3lapso';
    //                 if (notas[valores[`${val}-asignatura`]]===undefined){
    //                     notas[valores[`${val}-asignatura`]]={
    //                         asignatura:valores[`${val}-asignatura-nombre`],
    //                         [`notas-${lapso}`]:[Number(valores[val])]
    //                     }
    //                 }else{
    //                     notas[valores[`${val}-asignatura`]]={
    //                         ...notas[valores[`${val}-asignatura`]],
    //                         [`notas-${lapso}`]:
    //                             notas[valores[`${val}-asignatura`]][`notas-${lapso}`] 
    //                                 ?   [...notas[valores[`${val}-asignatura`]][`notas-${lapso}`], 
    //                                         Number(valores[val])
    //                                     ]
    //                                 :   [Number(valores[val])]
    //                     }
    //                 }
    //             }
                
    //         }
    //         return val;
    //     })
        
    //     Object.keys(nota).map(val=>{
    //         if (val.indexOf('lapso-')!==-1 && val.indexOf('-art112')===-1 && val.indexOf('-consejo')===-1 && val.indexOf('rasgos')===-1 && val.indexOf('-consejo')===-1){
    //             const data =  val.split('-');
    //             const lasnotas = notas[data[1]] ? notas[data[1]][`notas-${data[0]}`] : undefined;
                
    //             if (lasnotas!==undefined){
    //                 let resul = 0; 
    //                 lasnotas.map(res=>{
    //                     resul+=res;
    //                     return res
    //                 })
    //                 nota[val]= nota[val+'-rasgos']
    //                     ?   ((resul/lasnotas.length) * 0.90) + Number(nota[val+'-rasgos'] ? nota[val+'-rasgos'] : 0)
    //                     :   (resul/lasnotas.length)
    //             }    
    //         }
    //         return val
    //     })
    //     return nota
    // }
    const Guardar = async(valores)=>{
        
        
        let {est_datos}= state;
        let nota = Calculo_notas(valores);
        //{
            //asignatura: datos.asignatura,
            // docente: datos.docente
        //};
        // let notas= {};
        
        // Object.keys(valores).map(val=>{
            
        //     if (val.indexOf('Error-')===-1 && valores[val]!=='' && val!=='id' 
        //     ){
        //         nota[val]=valores[val];
        //         const notad = val.split('-');
        //         if (val.indexOf('nota-')!==-1 && notad.length===2 ){
        //             const lapso = valores[`${val}-lapso`] === '1er Lapso' ? '1lapso' : valores[`${val}-lapso`] === '2do Lapso' ? '2lapso' : '3lapso';
        //             if (notas[valores[`${val}-asignatura`]]===undefined){
        //                 notas[valores[`${val}-asignatura`]]={
        //                     asignatura:valores[`${val}-asignatura-nombre`],
        //                     [`notas-${lapso}`]:[Number(valores[val])]
        //                 }
        //             }else{
        //                 notas[valores[`${val}-asignatura`]]={
        //                     ...notas[valores[`${val}-asignatura`]],
        //                     [`notas-${lapso}`]:
        //                         notas[valores[`${val}-asignatura`]][`notas-${lapso}`] 
        //                             ?   [...notas[valores[`${val}-asignatura`]][`notas-${lapso}`], 
        //                                     Number(valores[val])
        //                                 ]
        //                             :   [Number(valores[val])]
        //                 }
        //             }
        //         }
                
        //     }
        //     return val;
        // })
        
        // Object.keys(nota).map(val=>{
        //     if (val.indexOf('lapso-')!==-1 && val.indexOf('-art112')===-1 && val.indexOf('-consejo')===-1 && val.indexOf('rasgos')===-1 && val.indexOf('-consejo')===-1){
        //         const data =  val.split('-');
        //         const lasnotas = notas[data[1]] ? notas[data[1]][`notas-${data[0]}`] : undefined;
                
        //         if (lasnotas!==undefined){
        //             let resul = 0; 
        //             lasnotas.map(res=>{
        //                 resul+=res;
        //                 return res
        //             })
        //             nota[val]= ((resul/lasnotas.length) * 0.90) + Number(nota[val+'-rasgos'] ? nota[val+'-rasgos'] : 0)
        //         }    
        //     }
        //     return val
        // })
        if (valores._id && !nota._id){
            nota._id=valores._id;
        }
        const pos = est_datos.findIndex(f=>f._id_estudiante===nota._id_estudiante)
        est_datos[pos]= {...nota, local:true};//_id_docente:datos.docente._id, docente:`${datos.docente.apellidos} ${datos.docente.nombres}`
        setState({...state, est_datos})
        // lo anterior
        // let nuevos= await conexiones.Guardar({_id:valores._id, valores:nota, multiples_valores:true},'uecla_Nota');
        // if (nuevos.Respuesta==='Ok'){
            setDialogo({...dialogo,open:false});
            //Seleccion({name:'tipo', resultados:formulario.datos})
        // }
        // return nuevos
    }  
    
    const AbrirS = async(columna, valor)=>{
        // console.log('Para la edicion por seccion', columna.field, valor)
        cambio({cargando:true})
        const {datos} = state;
        
        if (columna.field.indexOf('lapso-')!==-1 ){//&& columna.field.indexOf('-parc')===-1 && columna.field.indexOf('-consejo')===-1 && columna.field.indexOf('-art112')===-1 && columna.field.indexOf('-rasgos')===-1 
            const data = columna.field.split('-');
            let dat_asig={};
            let notas=[];
            Object.keys(valor).map(val=>{
                if ((val.indexOf(`lapso`)!==-1 && val.indexOf(`${data[0]}`)!==-1 && val.indexOf(`${data[1]}`)!==-1)
                    || (val.indexOf(`lapso`)===-1 && val.indexOf(`${data[1]}`)!==-1)
                    || valor[val]===data[1]
                    ||  notas.findIndex((element)=> val.indexOf(element)!==-1)!==-1
                ){
                    dat_asig[val]=valor[val];
                    notas = valor[val]===data[1] && val.indexOf('nota-')!==-1 ? [...notas, val.split('-')[1]] : notas;
                    
                }
                return val;
            })
            Object.keys(valor).map(val=>{
                if (notas.findIndex((element)=> val.indexOf(element)!==-1)!==-1
                ){
                    dat_asig[val]=valor[val];
                }
                return val;
            })
            let lapso = data[0]==='1lapso' ? '1er Lapso' : data[0]==='2lapso' ? '2do Lapso' : '3er Lapso';
            notas.map(val=>{
                if (dat_asig[`nota-${val}-lapso`]!==lapso){
                    delete dat_asig[`nota-${val}`];
                    delete dat_asig[`nota-${val}-lapso`];
                    delete dat_asig[`nota-${val}-asignatura`];
                    delete dat_asig[`nota-${val}-asignatura-nombre`];
                    delete dat_asig[`nota-${val}-nombre`];
                }
            })
        
            const nuevodatos = {...datos, tipo:'docente', 
                asignatura:{
                    asignatura:dat_asig[`asignatura_${data[1]}_nombre`],
                    asignatura:dat_asig[`asignatura_${data[1]}_titulo`],
                    _id:data[1],
                },
                docente:{
                    _id:dat_asig[`asignatura_${data[1]}_id_docente`],

                }
            }
            
            let nest_titulos = await Titulos_todos(`Titulos_Notas`, Config);
            nest_titulos = nest_titulos.map(val=>{
                return {...val, rowSpan:2}
            })
            const resultado = await conexiones.NNotas(nuevodatos);
        
            let fila1 = resultado.titulosn.datos[0].map(asig=>{
                return {
                    default : "",
                    field : `${asig.field}`,
                    formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                    orden: false, 
                    tipo : "",
                    title : asig.titulo,
                    type : "number",
                    lapso:asig.lapso,
                    vertical:false,
                    colSpan:7,                  

                }
            })
            
            const fila2 = resultado.titulosn.datos[1].map(asig=>{
                const definitiva = (asig.field.indexOf("1lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1)
                                    && (asig.field.indexOf("-art112")===-1  && asig.field.indexOf("-rasgos")===-1)
                return {
                    default : "",
                    field : `${asig.field}`,
                    formato : (dato)=> dato[asig.field] ? definitiva ? Nota_Entera(Number(dato[asig.field])) :Number(dato[asig.field]).toFixed(2) : '',
                    orden: false, 
                    tipo : "",
                    title : asig.titulo,
                    type : "number",
                    lapso:asig.lapso,
                    vertical:asig.vertical ? asig.vertical : false, 

                }
            })
            
            fila1 =[...nest_titulos, ...fila1];
            nest_titulos={filas:2 ,datos:[fila1,fila2], values:[...nest_titulos,...fila2]};
            
            let formato = {
                columna:5,
                value:[
                    {
                        label:  <div>
                                    <Typography fontWeight={'bold'}>{dat_asig[`asignatura_${data[1]}_docente`]}</Typography>    
                                </div>,
                        name:'titulo2',
                        nombre:'titulo2',
                        tipo:'mensaje1',
                        title: ``,
                        // variant:'h6',
                        solo:true
                    },
                    {
                        label:  <div>
                                    <Typography fontWeight={'bold'}>{dat_asig[`asignatura_${data[1]}_nombre`]}</Typography>
                                </div>,
                        name:'titulo2',
                        nombre:'titulo2',
                        tipo:'mensaje1',
                        title: ``,
                        // variant:'h6',
                        solo:true
                    },
                    {
                        "nombre": "foto",
                        "tipo": "Avatar",
                        "label": "Foto",
                        "placeholder": "Foto",
                        "title": "Foto",
                        "mensaje_error": "",
                        "disabled": true,
                        "key": "foto",
                        "name": "foto",
                        estilo:{height:130, width:130},
                    },
                    {
                        label:  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                                    <Typography fontWeight={'bold'}>{lapso}</Typography>
                                </div>,
                        name:'titulo3',
                        nombre:'titulo3',
                        tipo:'label1',
                        title: ``,
                        formato:(datos)=>{
                            let calcula = {};
                            let notas =0;
                            Object.keys(dat_asig).map(val=>{
                                calcula[val]= datos[val]
                                if (val.indexOf('nota-')!==-1 && val.split('-').length===2){
                                    notas++;
                                }
                                return
                            })
                            calcula= Calculo_notas(calcula);

                            return  <div style={{height:'100%',alignContent:'center'}}>
                                        <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                                        <Typography fontWeight={'bold'}>{lapso}</Typography>
                                        <Typography fontWeight={'bold'} title={`Notas calculadas: ${notas}`}>Nota: {calcula[`${data[0]}-${data[1]}`] ? calcula[`${data[0]}-${data[1]}`].toFixed(2) : '?'}</Typography>
                                    </div>
                        },
                        // variant:'h6',
                        solo:false
                    },
                    {
                        label:  '',
                        name:'titulo4',
                        nombre:'titulo4',
                        tipo:'mensaje1',
                        title: ``,
                        // variant:'h6',
                        solo:false
                    },
                    {
                        label:  '',
                        name:'titulo5',
                        nombre:'titulo5',
                        tipo:'mensaje1',
                        title: ``,
                        // variant:'h6',
                        solo:true
                    }
                    
                ]
            }
            
            let agregarI=[];
            let agregarD=[];
            let rasgos = [
                {
                    label: `Rasgo`,
                    name:`${data[0]}-${data[1]}-rasgos`,
                    nombre:`${data[0]}-${data[1]}-rasgos`,
                    tipo:'notas',
                    disabled: true,
                    title: `Rasgo`,
                    type:'number',
                    modificar:'Maximo_minimoR',
                    sinicono:true,
                },
                {
                    label: `Art112`,
                    name:`${data[0]}-${data[1]}-art112`,
                    nombre:`${data[0]}-${data[1]}-art112`,
                    tipo:'notas',
                    title: `Art112`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true,
                },
                {
                    label: `Consejo`,
                    name:`${data[0]}-${data[1]}-consejo`,
                    nombre:`${data[0]}-${data[1]}-consejo`,
                    tipo:'notas',
                    title: `Consejo`,
                    type:'number',
                    modificar:'Maximo_minimo',
                    sinicono:true,
                }
            ];
            Object.keys(dat_asig).map(val=>{
                if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1
                    && val.indexOf('lapso')===-1
                    ){
                    const pos = nest_titulos.values.findIndex(f=> `${f.field}`===val);
                    if ((pos!==-1) ){
                        agregarI=[...agregarI,{
                            name: `filename-${val}`,
                            nombre: `filename-${val}`,
                            tipo: "Imagen",
                            label: `Imagen ${nest_titulos.values[pos].title}`,
                            title: `Imagen ${nest_titulos.values[pos].title}`,
                        }];
                        agregarD=[...agregarD,{
                            label: `Observación ${nest_titulos.values[pos].title}`,
                            name:`descripcion-${val}`,
                            nombre:val,
                            tipo:'input',
                            title: `Observación ${nest_titulos.values[pos].title}`,
                            multiline: true,
                            maxRows: "2"
                        }]
                        formato.value =[...formato.value,
                            {
                                label: `${nest_titulos.values[pos].title}`,
                                name:val,
                                nombre:val,
                                tipo:'notas',
                                title: `${nest_titulos.values[pos].title}`,
                                type:'number',
                                modificar:'Maximo_minimo',
                                sinicono:true,
                            }
                        ]
                    }
                    
                }
                return val
            })
            agregarI =  agregarI.length===0
                    ? []
                    : [...agregarI,
                    {
                        label: `Rasgos`,
                        name:'titulo1',
                        nombre:'titulo1',
                        tipo:'mensaje1',
                        title: ``,
                    },
                    {
                        label: `Art112`,
                        name:'titulo12',
                        nombre:'titulo12',
                        tipo:'mensaje1',
                        title: ``,
                    },
                    {
                        label: `Consejo`,
                        name:'titulo13',
                        nombre:'titulo13',
                        tipo:'mensaje1',
                        title: ``,
                    },
            ]
            formato.columna= agregarI.length!==0 ? agregarI.length : 2;
            formato.value =[...formato.value,
                ...agregarI.length===0 
                    ? [
                        {
                            label: `SIN NOTAS`,
                            name:'titulo133',
                            nombre:'titulo133',
                            tipo:'mensaje1',
                            title: ``,
                            solo:true
                        }
                    ] 
                    :rasgos, 
                ...agregarI, 
                ...agregarD
            ];
            let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
            formulario={
                ...formulario,
                botones:[
                    {
                    name:'guardar', label:'Guardar', title:'Guardar ',
                    variant:"contained", color:"success", icono:<CheckIcon/>,
                    onClick: Guardar, validar:'true', 
                    sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                    },
                    {
                    name:'cancelar', label:'Cancelar', title:'Cancelar',
                    variant:"contained",  icono:<CancelIcon/>,
                    sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                    onClick: ()=>setDialogo({...dialogo,open:false})
                    },
                    permiso ?
                    {
                        name:'cancelar1', label:'Quitar', title:'Quitar notas',
                        variant:"contained", 
                        sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Eliminar : {}},
                        onClick: ()=> Quitar(valor, dat_asig)
                    }: null,
                ]
            }
            setDialogo({
                ...dialogo, 
                open: !dialogo.open,
                //tam:'l',
                Titulo:`NOTAS  DE  "${valor.apellidos} ${valor.nombres}"`,
                Cuerpo: <Formulario {...formulario}/>,
                Cerrar: ()=>setDialogo({...dialogo,open:false}),
            })
        }
        cambio({cargando:false})
    }

    const Quitar =async (valores, dat_asig)=>{
        setDialogo({
            ...dialogo, 
            open: false,
            //tam:'l',
            Titulo:``,
        })
        let listanota =[];
        Object.keys(dat_asig).map(val=>{
            const clave = val.split('-');
            if (clave[0]==='nota' && clave.length===2){
                const asig=valores[`${val}-asignatura`];
                listanota=[...listanota,
                    {   id:clave[1],_id:clave[1],
                        //nota
                        [`${val}`]:valores[val],
                        //nombre
                        [`${val}-nombre`]:valores[`${val}-nombre`],
                        //lapso
                        [`${val}-lapso`]:valores[`${val}-lapso`],
                        //asignatura
                        [`${val}-asignatura`]:valores[`asignatura_${asig}_nombre`],

                        titulo:`${valores[`asignatura_${asig}_nombre`]} ${valores[`${val}-nombre`]} ${valores[`${val}-lapso`]} ${valores[`${val}`]}`
                    }
                ]
            }
            return val
        })
        let formato = {
            columna:1,
            value:[
                {
                    "nombre": "quitar",
                    "tipo": "lista_multiuso",
                    "label": "Selecciones Nota(s) a quitar",
                    "placeholder": "Selecciones Nota(s) a quitar",
                    "title": "Selecciones Nota(s) a quitar",
                    "mensaje_error": "",
                    "disabled": false,
                    "numberOfLines": "",
                    "lista": listanota,
                    "getOptionLabel": [
                        "titulo"
                    ],
                    "key": "quitar",
                    "name": "quitar",
                    "multiline": false,
                    "multiple": true
                }
                
            ]
        }
        let formulario = await genera_formulario({ valores:{}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                name:'guardar', label:'Procesar', title:'Procesar',
                variant:"contained", color:"success", icono:<CheckIcon/>,
                onClick: (nuevo)=> GuardarQ(valores, nuevo), validar:'true', 
                sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                name:'cancelar', label:'Cancelar', title:'Cancelar',
                variant:"contained",  icono:<CancelIcon/>,
                sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                onClick: ()=>setDialogo({...dialogo,open:false})
                }
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            //tam:'l',
            Titulo:`QUITAR NOTAS`,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    const GuardarQ=(valores, nuevos)=>{
        const nuevo ={...valores}
        
        nuevos.quitar.map(val=>{
            Object.keys(val).map( clave=>{
                if (['id','_id','titulo'].indexOf(clave)===-1){
                    delete nuevo[clave]
                }

            })
            return val
        })
        Guardar(nuevo)
    }
    const Abrir = async(columna, valor)=>{
        const {datos, est_titulos} = state;
        // let editable = datos.perlapso ? 
        if (datos.tipo==='seccion' && permiso ){
            AbrirS(columna, valor);
            return
        }else if(datos.tipo==='seccion'){
            return
        }
        if (['cedula','nombres','apellidos'].indexOf(columna.field)!==-1
            || (columna.field.indexOf('lapso-')!==-1 && columna.field.indexOf('-parc')===-1 && columna.field.indexOf('-consejo')===-1 && columna.field.indexOf('-art112')===-1 && columna.field.indexOf('-rasgos')===-1 )
            || (Ver_Valores().User.categoria===3 && (columna.field.indexOf('-consejo')!==-1 || columna.field.indexOf('-art112')!==-1 && columna.field.indexOf('-rasgos')===-1)
            || (!editable && !permiso)
        )
        ){
            return
        }
        
        const valorN= {

            [columna.field]:valor[columna.field],
            ['descripcion-'+columna.field]:valor['descripcion-'+columna.field],
            ['filename-'+columna.field]:valor['filename-'+columna.field],
            nombres:valor.nombres, apellidos: valor.apellidos
        }
        let formato = {
            columna:1,
            value:[
                {
                    label: `${valor.nombres} ${valor.apellidos}`,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:100, width:100},
                    solo:true
                },
                {
                    label: `${datos.asignatura.titulo}`,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    solo:true
                }
                
            ]
        }
        let lapso = null;
        let Titulo = 'Notas';
        Object.keys(valorN).map(val=>{
            if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1){
                const pos = est_titulos.values.findIndex(f=> `${f.field}`===val);
                if (pos!==-1 ){
                    let agregar=[]
                    Titulo = est_titulos.values[pos].title;
                    
                    if (lapso===null || lapso.value!==est_titulos.values[pos].lapso.value){
                        agregar=[
                            {
                                label: `${est_titulos.values[pos].lapso.titulo}`,
                                name:est_titulos.values[pos].lapso.value,
                                nombre:est_titulos.values[pos].lapso.value,
                                tipo:'mensaje1',
                                title: `${est_titulos.values[pos].lapso.titulo}`,
                                variant:'h6',
                                solo:true
                            }
                        ]
                        lapso=est_titulos.values[pos].lapso;
                    }

                    formato.value =[...formato.value,
                        ...agregar,
                        {
                            label: `${est_titulos.values[pos].title}`,
                            name:val,
                            nombre:val,
                            tipo:'notas',
                            title: `${est_titulos.values[pos].title}`,
                            type:'number',
                            modificar: val.indexOf('rasgos')!==-1 ? 'Maximo_minimoR' : 'Maximo_minimo',
                            sinicono:true,
                        },
                        {
                            name: `filename-${val}`,
                            nombre: `filename-${val}`,
                            tipo: "Imagen",
                            label: "Imagen",
                            title: "Imagen",
                        },
                        {
                            label: `Observación`,
                            name:`descripcion-${val}`,
                            nombre:val,
                            tipo:'input',
                            title: `Observación`,
                            multiline: true,
                            maxRows: "2"
                        }
                    ]
                }
                
            }
            return val
        })
        
        if ((!datos.perlapso || !datos.perlapso[lapso.value]) && !permiso){
            return
        }
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            
            tam:'xs',
            fullWidth: true,
            Titulo,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }
    const Abrir1 = async(valor) =>{
        console.log(valor)
        const {datos, est_titulos} = state;
        if ((datos.tipo==='seccion' ) || (!editable && !permiso)){
            return
        }
        let formato = {
            columna:5,
            value:[
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{valor.nombres} {valor.apellidos}</Typography>    
                            </div>,
                    name:'titulo0',
                    nombre:'titulo0',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    label:  <div>
                                <Typography fontWeight={'bold'}>{datos.asignatura.asignatura }</Typography>    
                            </div>,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                },
                {
                    "nombre": "foto",
                    "tipo": "Avatar",
                    "label": "Foto",
                    "placeholder": "Foto",
                    "title": "Foto",
                    "mensaje_error": "",
                    "disabled": true,
                    "key": "foto",
                    "name": "foto",
                    estilo:{height:130, width:130},
                },
                {
                    label:  <div style={{height:'100%',alignContent:'center'}}>
                                <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                            </div>,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'label1',
                    title: ``,
                    formato:(valores)=>{
                        let calcula= Calculo_notas(valores);
                        return  <div style={{height:'100%',alignContent:'center'}}>
                                    <Typography fontWeight={'bold'}>{datos.grado} {datos.seccion}</Typography>
                                    
                                    <Typography fontWeight={'bold'}>1er: {calcula[`1lapso-${datos.asignatura._id}`] ? calcula[`1lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`1lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`1lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>2do: {calcula[`2lapso-${datos.asignatura._id}`] ? calcula[`2lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`2lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`2lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                    <Typography fontWeight={'bold'}>3er: {calcula[`3lapso-${datos.asignatura._id}`] ? calcula[`3lapso-${datos.asignatura._id}`].toFixed(2) : ''} ptos {calcula[`3lapso-${datos.asignatura._id}-rasgos`] ? `Rasgo: ${calcula[`3lapso-${datos.asignatura._id}-rasgos`]}` : ''}</Typography>
                                </div>
                    },
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    //solo:true
                },
                {
                    label: ``,
                    name:'titulo4',
                    nombre:'titulo4',
                    tipo:'mensaje1',
                    title: ``,
                    // variant:'h6',
                    solo:true
                }
                
            ]
        }
        let lapso = null;
        let agregarI=[];
        let agregarD=[];
        Object.keys(valor).map(val=>{
            if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1){
                const pos = est_titulos.values.findIndex(f=> `${f.field}`===val);
                if ((pos!==-1 && val.indexOf('lapso-')===-1)){
                    let agregar=[]
                    if (lapso===null || lapso.value!==est_titulos.values[pos].lapso.value){
                        agregar=[
                            {
                                label: `${est_titulos.values[pos].lapso.titulo.split(' ')[0]}`,
                                name:est_titulos.values[pos].lapso.value,
                                nombre:est_titulos.values[pos].lapso.value,
                                tipo:'mensaje1',
                                title: `${est_titulos.values[pos].lapso.titulo}`,
                                //variant:'h6',
                                //solo:false
                            }
                        ]
                        lapso=est_titulos.values[pos].lapso;
                        agregarI=[...agregarI, {
                                                    ...agregar[0],
                                                    name:est_titulos.values[pos].lapso.value+'I',
                                                    nombre:est_titulos.values[pos].lapso.value+'I',
                                                }
                        ];
                        agregarD=[...agregarD, {
                                ...agregar[0],
                                name:est_titulos.values[pos].lapso.value+'D',
                                nombre:est_titulos.values[pos].lapso.value+'D',
                            }
                        ];
                    }
                        agregarI=[...agregarI,{
                            name: `filename-${val}`,
                            nombre: `filename-${val}`,
                            tipo: "Imagen",
                            label: `Imagen ${est_titulos.values[pos].title}`,
                            title: `Imagen ${est_titulos.values[pos].title}`,
                        }];
                        agregarD=[...agregarD,{
                            label: `Observación ${est_titulos.values[pos].title}`,
                            name:`descripcion-${val}`,
                            nombre:val,
                            tipo:'input',
                            title: `Observación ${est_titulos.values[pos].title}`,
                            multiline: true,
                            maxRows: "2"
                        }]
                    
                    formato.value =[...formato.value,
                        ...agregar,
                        {
                            label: `${est_titulos.values[pos].title}`,
                            name:val,
                            nombre:val,
                            tipo:'notas',
                            title: `${est_titulos.values[pos].title}`,
                            type:'number',
                            modificar:'Maximo_minimo',
                            sinicono:true,
                            disabled: (!datos.perlapso || !datos.perlapso[lapso.value]) && !permiso
                            
                        }
                    ]
                }
                
            }
            return val
        })
        formato.columna= agregarI.length;
        formato.value =[...formato.value, ...agregarI, ...agregarD];
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:formato.columna>8 ?'l' : null,
            Titulo:`NOTAS `,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }

    const Repetido = (pos,lista, valores)=>{
        let cantidad=0;
        while (true) {
            const p = lista.indexOf(valores[pos+cantidad].field)
            if (p===-1 && valores[pos+cantidad].field.indexOf('nota-')!==-1){
                break
            }else if (valores[pos+cantidad].field.indexOf('nota-')===-1){
                cantidad=-1;
                break
            }else{
                cantidad+=1
            }
        }
        
        return cantidad
    }
    const FormatoBoletas = async()=>{
        const {est_datos, est_titulos, datos} = state;
        
        // let campos = {
        //     No:'', apellidos:'apellidos', nombres:'nombres'
        // };
        // est_titulos.values.map(val=>{
        //     if (['Cedula','Nombres','Apellidos'].indexOf(val.title)===-1){
        //         campos={...campos, [val.field]:''}
        //     }
        //     return val
        // })
        // delete campos.cedula;
        // campos={...campos,
        //     cedula:'', 
        //     fecha_nacimiento:'',
        //     lugar_nacimiento:'',
        // }
        // let data =[
        //     campos
        // ]
        
        // const agregar = data[0];
        // for (var i=0;i<3;i++){
        //     data=[...data, agregar];
        // }
        // est_datos.map((dat,i)=>{
        //     let nuevo = {No:i+1}
        //     Object.keys(campos).map(val=>{
        //         if (['No','_id_estudiante','seccion','grado'].indexOf(val)===-1){
        //             nuevo[val]= val==='fecha_nacimiento' ? moment(dat[val]).format("DD/MM/YYYY") :dat[val] === 0 ? '' : typeof dat[val]==='number' ? Nota_Entera(Number(dat[val])) : dat[val]
        //         }
                
        //         return val
        //     })
        //     data=[...data, nuevo]
        //     return dat
        // })
        // let asigna=[];
        // let trismestre=[];
        // let subtitulo=['N°',"APELLIDOS Y NOMBRES","APELLIDOS Y NOMBRES"];
        // est_titulos.datos[0].map(val=>{
        //     if (['Cedula','Nombres','Apellidos','ESTUDIANTES'].indexOf(val.title)===-1){
        //         asigna=[...asigna, val.title,val.title,val.title,val.title,val.title,val.title,val.title];
        //         trismestre=[...trismestre,"1°","1°","2°","2°","3°","3°",""];
        //         subtitulo=[...subtitulo,'C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','NOTA DEF.']
        //     }
        //     return val
        // })
        
        // asigna=[...asigna,'','','',''];
        // subtitulo=[...subtitulo, 'CEDULA', 'FECHA','LUGAR',datos.grado, datos.seccion];
        
        // const ano={
        //     '1er año':'PRIMER',
        //     '2do año':'SEGUNDO',
        //     '3er año':'TERCER',
        //     '4to año':'CUARTO',
        //     '5to año':'QUINTO'
        // }
        // const Titulos = [
        //     [`ASIGNATURAS`,"ASIGNATURAS", "ASIGNATURAS",...asigna],
        //     ["TRIMESTRE","TRIMESTRE","TRIMESTRE",...trismestre],
        //     subtitulo,
        //     ['No',`${ano[datos.grado]} AÑO SECCION "${datos.seccion}"`,"",""],
        //     ['No',`${datos.periodo}`,"",""]
            
        // ];
        
        await Formato_Boleta(est_datos, est_titulos, datos);
        // let file = await (await fetch("utilidad/boletas.xlsm")).arrayBuffer();
        // NotasExcell(data, Titulos, file, "Notas", `Boletas-${datos.grado}-${datos.seccion}-${datos.periodo}.xlsm`, "boleta");
    }
    const FormatoCPD = async() =>{
        const {est_datos, est_titulos, datos} = state;
        
        let campos = {
            No:'', apellidos:'apellidos', nombres:'nombres',
        };
        est_titulos.values.map(val=>{
            if (['Cedula','Nombres','Apellidos'].indexOf(val.title)===-1){
                campos={...campos, [val.field]:''}
            }
            return val
        })
        delete campos.cedula;
        
        let data =[
            campos
        ]
        const agregar = data[0];
        for (var i=0;i<6;i++){
            data=[...data, agregar];
        }
        est_datos.map((dat,i)=>{
            let nuevo = {No:i+1}
            Object.keys(campos).map(val=>{
                if (['No','_id_estudiante','seccion','grado','cedula'].indexOf(val)===-1){
                    nuevo[val]= dat[val] === 0 ? '' : typeof dat[val]==='number' ? Nota_Entera(Number(dat[val])) : dat[val]
                }
                
                return val
            })
            data=[...data, nuevo]
            return dat
        })
        
        let asigna=[];
        let trismestre=[];
        let subtitulo=['N°',"APELLIDOS Y NOMBRES","APELLIDOS Y NOMBRES"];
        est_titulos.datos[0].map(val=>{
            if (['Cedula','Nombres','Apellidos',"ESTUDIANTES"].indexOf(val.title)===-1){
                asigna=val.title!=="ESTUDIANTES" 
                        ?   [...asigna, val.title,val.title,val.title,val.title,val.title,val.title,val.title]
                        :   asigna;
                trismestre=[...trismestre,"1°","1°","2°","2°","3°","3°",""];
                subtitulo=[...subtitulo,'C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','C. PROBABLE','C. PARCIAL','NOTA DEF.']
            }
            return val
        })
        
        const ano={
            '1er año':'PRIMER',
            '2do año':'SEGUNDO',
            '3er año':'TERCER',
            '4to año':'CUARTO',
            '5to año':'QUINTO'
        }
        const Titulos = [
            ["UNIDAD EDUCATIVA","", "","","","","","","","","","","", "","","","","","","","","","","", "","","","","","","","","","","", "","","","","","","","","","","", "","","","","","","","","","","", "","","","","","","","","",""],
            ['"COLEGIO LIBERTADORES DE AMÉRICA"',"",""],
            ['SANTA ANA DE CORO-MIRANDA-ESTADO FALCÓN',"","",`CALIFICACIONES PARCIALES Y DEFINITIVAS ${datos.periodo}`],
            [`ASIGNATURAS`,"ASIGNATURAS", "ASIGNATURAS",...asigna],
            ["TRIMESTRE","TRIMESTRE","TRIMESTRE",...trismestre],
            subtitulo,
            ['No',`${ano[datos.grado]} AÑO SECCION "${datos.seccion}"`,"",""],
            ['No',`${datos.periodo}`,"",""]
            
        ];
        let file = await (await fetch("utilidad/parcialdefinitiva.xlsm")).arrayBuffer();
        NotasExcell(data, Titulos, file, "Notas", `Parcial-Definitiva-${datos.grado}-${datos.seccion}-${datos.periodo}.xlsm`);
    }
    const FormatoEvaluacion = async()=>{
        const {est_datos, est_titulos, datos} = state;
        
        let campos = {
            No:'', apellidos:'apellidos', nombres:'nombres',
            nl11:'1lapso,nota-',nl12:'1lapso,nota-', nl13:'1lapso,nota-', nl14:'1lapso,nota-', nl15:'1lapso,nota-', nl16:'1lapso,-rasgos', calEv1:'1lapso,1er Lapso', art1:'1lapso,-art112', cs1:'1lapso,-consejo',def1:'',
            nl21:'2lapso,nota-',nl22:'2lapso,nota-', nl23:'2lapso,nota-', nl24:'2lapso,nota-', nl25:'2lapso,nota-', nl26:'2lapso,-rasgos', calEv2:'2lapso,2do Lapso', art2:'2lapso,-art112', cs2:'2lapso,-consejo',def2:'',
            nl31:'3lapso,nota-',nl32:'3lapso,nota-', nl33:'3lapso,nota-', nl34:'3lapso,nota-', nl35:'3lapso,nota-', nl36:'3lapso,-rasgos', calEv3:'3lapso,3er Lapso', art3:'3lapso,-art112', cs3:'3lapso,-consejo',def3:'',
            dfa:''
        };
        let nuevocampos = {};
        Object.keys(campos).map(camp=>{
            const opcion = campos[camp].split(',');
            let pos = -1;
            if (opcion.length===1  ){
                pos = est_titulos.values.findIndex(f=>f.field===opcion[0]);
            }else if (opcion.length===2){
                pos = est_titulos.values.findIndex(f=> f.lapso && (
                    (f.lapso.value===opcion[0] && f.field.indexOf(opcion[1])===1) // nota
                    || (f.lapso.value===opcion[0] && f.field.indexOf(opcion[1])!==-1))   // rasgos, art112, concejo
                    || (f.lapso===undefined && f.field.indexOf(opcion[0])===0 && f.title===opcion[1])   // lapso
                );
            }
            const mas = pos!==-1 && opcion[1]==='nota-' ? Repetido(pos, Object.values(nuevocampos),est_titulos.values) : 0
            pos= pos!==-1 && opcion[1]==='nota-'  ? mas===-1 ? pos=mas : pos +mas : pos
            nuevocampos[camp]= pos!==-1 ? est_titulos.values[pos].field : campos[camp];
            return camp
        })
        
        campos=nuevocampos
        let data =[
            campos
            
        ]
        const agregar = data[0];
        for (var i=0;i<6;i++){
            data=[...data, agregar];
        }
        data=[
            ...data,
            {
                No:'N°', apellidos:'APELLIDOS', nombres:'NOMBRES',
                nl11:'N',nl12:'N', nl13:'N', nl14:'N', nl15:'N', nl16:'N', calEv1:'Calificación de Evaluaciones', art1:'Aplicación de Art. 112', cs1:'Modificación de Consejo de Sección',def1:'DEFINITIVA',
                nl21:'N',nl22:'N', nl23:'N', nl24:'N', nl25:'N', nl26:'N', calEv2:'Calificación de Evaluaciones', art2:'Aplicación de Art. 112', cs2:'Modificación de Consejo de Sección',def2:'DEFINITIVA',
                nl31:'N',nl32:'N', nl33:'N', nl34:'N', nl35:'N', nl36:'N', calEv3:'Calificación de Evaluaciones', art3:'Aplicación de Art. 112', cs3:'Modificación de Consejo de Sección',def3:'DEFINITIVA',
                dfa:'DEFINITICA AÑO'
            }
        ]
        est_datos.map((valor,i)=>{
            let nuevo={
                    No:i+1, apellidos:valor.apellidos, nombres:valor.nombres,
                    nl11:'',nl12:'', nl13:'', nl14:'', nl15:'', nl16:'', calEv1:'', art1:'', cs1:'',def1:'',
                    nl21:'',nl22:'', nl23:'', nl24:'', nl25:'', nl26:'', calEv2:'', art2:'', cs2:'',def2:'',
                    nl31:'',nl32:'', nl33:'', nl34:'', nl35:'', nl36:'', calEv3:'', art3:'', cs3:'',def3:'',
                    dfa:''
            }
            
            Object.keys(campos).map(val=>{
                
                nuevo[val]= val=== 'No' 
                            ? i+1 
                            : val==='calEv1' || val==='calEv2' || val==='calEv3'
                            ? valor[campos[val]] ? Nota_Entera(Number(valor[campos[val]] ? valor[campos[val]] : 0)): ''
                            : val==='def1'
                            ? nuevo['calEv1'] ? Nota_Entera(Number(nuevo['cs1'] ? nuevo['cs1'] : nuevo['art1'] ? nuevo['art1']  : nuevo['calEv1'])) : ''
                            : val==='def2'
                            ? nuevo['calEv2'] ? Nota_Entera(Number(nuevo['cs2'] ? nuevo['cs2'] : nuevo['art2'] ? nuevo['art2']  : nuevo['calEv2'])) : ''
                            : val==='def3'
                            ? nuevo['calEv3'] ? Nota_Entera(Number(nuevo['cs3'] ? nuevo['cs3'] : nuevo['art3'] ? nuevo['art3']  : nuevo['calEv3'])) : ''
                            : val==='dfa'
                            ? nuevo['def1'] && nuevo['def2'] && nuevo['def3'] ? Nota_Entera(((Number(nuevo['def1']) + Number(nuevo['def2']) +Number(nuevo['def3'] ))/3)) : ""
                            : valor[campos[val]]
                
                return val
            })
            data=[...data, nuevo]
            return valor
        })
        const ano={
            '1er año':'PRIMER',
            '2do año':'SEGUNDO',
            '3er año':'TERCER',
            '4to año':'CUARTO',
            '5to año':'QUINTO'
        }
        const Titulos = [
            [`EVALUACION DE LA ACTUACION DEL ESTUDIANTE DURANTE EL AÑO ESCOLAR ${datos.periodo}`,"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            ["UNIDAD EDUCATIVA COLEGIO","", "","","","","","","RASGOS","Calificación de Evaluaciones","Aplicación de Art. 112","Modificación de Consejo de Sección","DEFINITIVA","","","","","","RASGOS","Calificación de Evaluaciones","Aplicación de Art. 112","Modificación de Consejo de Sección","DEFINITIVA","","","","","","RASGOS","Calificación de Evaluaciones","Aplicación de Art. 112","Modificación de Consejo de Sección","DEFINITIVA","DEFINITIVA AÑO"],
            ['"LIBERTADORES DE AMÉRICA"',"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            ['SANTA ANA DE CORO-MIRANDA-ESTADO FALCÓN',"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            [`Áre de Formación: ${datos.asignatura.asignatura}`,"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            [`Curso: ${ano[datos.grado]} AÑO Sección: ${datos.seccion}`,"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            [`Docente: ${datos.docente.nombres} ${datos.docente.apellidos}`,"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
            [`Lapso: I, II, III Año Escolar ${datos.periodo}`,"", "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""],
        ];
        let file = await (await fetch("utilidad/actuacionestudiante.xlsm")).arrayBuffer();
        NotasExcell(data, Titulos, file, "Notas", `Actuacion-Estudiante-${datos.asignatura.asignatura}-${datos.grado}-${datos.seccion}-${datos.periodo}.xlsm`, 'parcialdefinitiva');
    }

    React.useEffect(()=>{
        const {User} = Ver_Valores();
        let active = true;
        if (!state.cargando && state.est_datos && state.est_datos.filter(f=>f.local).length!==0){
            
            confirmAlert({
                title: `Guardar`,
                message:`Realizo cambios en notas, desea guardar?`,
                buttons: [
                    {label: 'SI', onClick: async()=>{
                       await GuardarTodo();
                    }},
                    {label: 'NO'}
                ]
            });
        }
        
        if (!cargando) {
        return undefined;
        }

        if (cargando && state.formulario===undefined){
            //Inicio
            (async () => {
                let formulario = await genera_formulario({ valores:{}, campos: Form_todos('Form_Notas') });
                formulario.titulos[0].value.grado.onChange = Seleccion;
                formulario.titulos[0].value.periodo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.onChange = Seleccion;
                formulario.titulos[0].value.lista.onChange = Seleccion;
                formulario.titulos[0].value.periodo.value= formulario.titulos[0].value.periodo.lista.length!==0 ? formulario.titulos[0].value.periodo.lista[0]:null;
                formulario.titulos[0].value.grado.value= formulario.titulos[0].value.grado.lista.length!==0 ? formulario.titulos[0].value.grado.lista[0]:null;
                formulario.titulos[0].value.tipo.value= formulario.titulos[0].value.tipo.lista.length!==0 
                            ? Number(User.categoria)===3 && !permiso
                                ? formulario.titulos[0].value.tipo.lista[1]
                                : formulario.titulos[0].value.tipo.lista[0]
                            : null;
                formulario.titulos[0].value.lista.value= formulario.titulos[0].value.lista.lista.length!==0 ? formulario.titulos[0].value.lista.lista[0]:null;
                // formulario.titulos[0].value.lista.placeholder='Sección';
                
                
                if (active) {
                    cambio({formulario, datos:[], cargando:false});
                    setCargando(false)
                    setTimeout(() => {
                        Seleccion({resultados:{
                            periodo:formulario.titulos[0].value.periodo.value,
                            grado:formulario.titulos[0].value.grado.value,
                            tipo:formulario.titulos[0].value.tipo.value,
                            lista:formulario.titulos[0].value.lista.value
                        }})
                    }, 700);
                }
            })();
            return
        }
        (async () => {
            //Buscar los datos
            const {resultados}= cargando;
            
            // let datos= await conexiones.Leer_C([`uecla_horario`, ...resultados.tipo.value==='seccion' ? ['uecla_asignatura','uecla_docente'] :[]], 
            //     {
            //         [`uecla_horario`]:{$and:[{'valores.periodo':resultados.periodo.periodo},{'valores.tipo':resultados.tipo.value}]},//{$text: {$search: resultados.carrera.nombres, $caseSensitive: false}},
            //         ...resultados.tipo.value==='seccion' ? {uecla_asignatura:{}, uecla_docente:{}} :{}
            //     }
            // );
            let est_datos=[];
            let est_cantidad=0;
            //Lo de la primera fila
            // let est_titulos = await Titulos_todos(`Titulos_Notas`, Config);
            // est_titulos = est_titulos.map(val=>{
            //     return {...val, }
            // })
            
            let formulario_docente
            let datos= await conexiones.Leer_C(['uecla_docente'], 
                {
                    uecla_docente:{},
                }
            );
            if (datos.Respuesta==='Ok'){
                // let asignaturas = datos.datos[`uecla_asignatura`] ? datos.datos[`uecla_asignatura`].map(val=>{return {_id:val._id,...val.valores}}) : [];
                let docentes = datos.datos[`uecla_docente`] ? datos.datos[`uecla_docente`].map(val=>{return {_id:val._id,...val.valores}}) : [];
                
                // datos= datos.datos[`uecla_horario`]
                datos ={
                    periodo:resultados.periodo.periodo, 
                    tipo:resultados.tipo.value,
                    perlapso: cargando.perlapso
                };
                
                if (resultados.tipo.value==='seccion'){
                    // datos = datos.filter(f=>{
                    //     return f.valores.titulo===`${resultados.grado.titulo} ${resultados.lista.titulo}`
                    // }).map(val=>{return {...val.valores, titulo:val.valores.titulo, _id:val._id}})
                    // console.log(datos)
                    docentes = docentes.filter(f=>{
                        const o = f.grados ? f.grados.filter(f1=>f1.value===resultados.grado.value):[];
                        return o.length!==0
                    }).map(val=>{return {...val, titulo:`${val.nombres} ${val.apellidos}`}});
                    
                }else if(resultados.tipo.value==='docente'){
                    
                    docentes = docentes.filter(f=> f.asignaturas && f.asignaturas.length!==0).map(val=>{return {...val, titulo:`${val.nombres} ${val.apellidos}`}});
                    docentes = Number(User.categoria)===3 && !permiso ? docentes.filter(f=>f._id===User._id) : docentes;
                }

                const formulario1 = await genera_formulario({ valores:{...resultados}, campos: Form_todos('Form_Notas') });
                const formulario ={...formulario1};
                formulario.titulos[0].value.grado.onChange = Seleccion;
                formulario.titulos[0].value.periodo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.disabled=Number(User.categoria)===3 && !permiso;
                formulario.titulos[0].value.lista.onChange = Seleccion;
                if(resultados.tipo.value==='seccion'){
                    delete formulario.titulos[0].value.tipo.lista[2];
                    let pos = Ver_Valores().config.Listas.lista_colegio_grado.findIndex(f=> f._id===resultados.grado._id);
                    if (pos===-1){
                        formulario.titulos[0].value.grado.value=Ver_Valores().config.Listas.lista_colegio_grado[0];
                        datos ={
                            ...datos,
                            grado:Ver_Valores().config.Listas.lista_colegio_grado[0].titulo,
                        };
                    }
                    pos = formulario.titulos[0].value.lista.lista.findIndex(f=> f._id===resultados.lista._id);
                    if (pos===-1){
                        formulario.titulos[0].value.lista.value=formulario.titulos[0].value.lista.lista[0];
                        
                    }
                    datos={
                        ...datos,
                        grado:formulario.titulos[0].value.grado.value.titulo,
                        seccion:formulario.titulos[0].value.lista.value.titulo
                    }
                    
                } else if(resultados.tipo.value==='docente'){
                    // formulario.titulos[1]={
                    //     listo:true, multiples:true,
                    //     value:{
                    //         grado_docente:{
                    //             ...formulario.titulos[0].value.grado,
                    //             key:'grado_docente', name:'grado_docente',
                    //             nombre:'grado_docente',
                    //             value:resultados.grado_docente
                    //         },
                    //         seccion:{
                    //             ...formulario.titulos[0].value.lista,
                    //             key:'seccion', name:'seccion',
                    //             nombre:'seccion',
                    //             value:resultados.seccion
                    //         },
                    //     }
                    // }
                    formulario.titulos[0].value.grado_docente={
                        ...formulario.titulos[0].value.grado,
                        key:'grado_docente', name:'grado_docente',
                        nombre:'grado_docente',
                        value:resultados.grado_docente
                    };
                    formulario.titulos[0].value.seccion={
                        ...formulario.titulos[0].value.lista,
                        key:'seccion', name:'seccion',
                        nombre:'seccion',
                        value:resultados.seccion
                    };
                    // cambia grado a docente
                    formulario.titulos[0].value.grado.lista=docentes;
                    formulario.titulos[0].value.grado.placeholder='Doncente';
                    formulario.titulos[0].value.grado.title='Doncente';
                    formulario.titulos[0].value.grado.label='Doncente';
                    let pos = docentes.findIndex(f=> f._id===resultados.grado._id)
                    if (pos===-1){
                        formulario.titulos[0].value.grado.value=docentes[0];
                    }
                    datos={...datos, docente:formulario.titulos[0].value.grado.value}

                    //cambia a lista a asignatura
                    const asig_docente= formulario.titulos[0].value.grado.value.asignaturas.map(asig=>{return{...asig, titulo:`${asig.grado.titulo} ${asig.asignatura}`}});
                    formulario.titulos[0].value.lista.lista= asig_docente; 
                    formulario.titulos[0].value.lista.placeholder='Asignatura';
                    formulario.titulos[0].value.lista.title='Asignatura';
                    formulario.titulos[0].value.lista.label='Asignatura';
                    pos = asig_docente.findIndex(f=> f._id===resultados.lista._id)
                    if (pos===-1){
                        formulario.titulos[0].value.lista.value=asig_docente[0];
                    }
                    datos={...datos, asignatura:formulario.titulos[0].value.lista.value}

                    // listado de grado
                    const grado_docente= formulario.titulos[0].value.grado.value.grados;
                    
                    formulario.titulos[0].value.grado_docente.disabled=true;
                    formulario.titulos[0].value.grado_docente.lista= grado_docente; 
                    
                    formulario.titulos[0].value.grado_docente.value=formulario.titulos[0].value.lista.value ? formulario.titulos[0].value.lista.value.grado : ''
                    // pos = grado_docente.findIndex(f=> resultados.grado_docente && f._id===resultados.grado_docente._id)
                    // if (pos===-1){
                    //     // formulario.titulos[1].value.grado_docente.value=grado_docente[0];
                    //     formulario.titulos[1].value.grado_docente.value=formulario.titulos[0].value.lista.value.grado
                    // }
                    datos={...datos, grado:formulario.titulos[0].value.grado_docente.value.titulo}

                    // listado seccion
                    const secciones = Ver_Valores().config.Listas.lista_colegio_seccion;
                    formulario.titulos[0].value.seccion.lista= secciones; 
                    pos = secciones.findIndex(f=> resultados.seccion && f._id===resultados.seccion._id)
                    if (pos===-1){
                        formulario.titulos[0].value.seccion.value=secciones[0];
                    }
                    datos={...datos, seccion:formulario.titulos[0].value.seccion.value.titulo}

                    // formulario.titulos=[...formulario.titulos]
                    // delete formulario.titulos[0].value.lista
                    
                    // formulario_docente = await genera_formulario({ valores:{}, campos: Form_todos('Form_Notas_Docente') });
                }
                
                const resultado = await conexiones.NNotas(datos);
                est_datos=resultado.seccion.sort((a,b)=> a.cedula>b.cedula ? 1 : -1);
                est_cantidad= est_datos.length;
                
                let est_titulos = await TitulosNotas(resultado.titulosn, resultados.tipo);
                // let fila1 = resultado.titulosn.datos[0].map(asig=>{
                //     return {
                //         default : "",
                //         field : `${asig.field}`,
                //         formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                //         orden: false, 
                //         tipo : "",
                //         title : asig.titulo,
                //         type : "number",
                //         lapso:asig.lapso,
                //         vertical:false,
                //         colSpan:7,                  

                //     }
                // })
                
                // const fila2 = resultado.titulosn.datos[1].map(asig=>{
                //     const definitiva = (asig.field.indexOf("1lapso-")!==-1 || asig.field.indexOf("2lapso-")!==-1 || asig.field.indexOf("3lapso-")!==-1)
                //                         && (asig.field.indexOf("-art112")===-1 && asig.field.indexOf("-rasgos")===-1) 
                //     const tipo =resultados.tipo.value;
                    
                //     return {
                //         default : "",
                //         field : `${asig.field}`,
                //         formato : (dato)=> dato[asig.field] 
                //             ?   definitiva 
                //                     ?   Nota_Entera(Number(tipo==='seccion' && dato[`${asig.field}-art112`] 
                //                                             ?   dato[`${asig.field}-art112`]
                //                                             :   dato[asig.field]
                //                                         ))
                //                     :   Number(dato[asig.field]).toFixed(2) 
                //             :   '',
                //         orden: false, 
                //         tipo : "",
                //         title : asig.titulo,
                //         type : "number",
                //         lapso:asig.lapso,
                //         vertical:asig.vertical ? asig.vertical : false, 

                //     }
                // })
                
                
                // est_titulos=[
                //     {
                //         ...est_titulos[0],
                //         fija:true, minWidth:400,//window.innerWidth * 0.3,
                //         title:'APELLIDOS NOMBRES',
                //         formato:(dato)=>{
                //             return `${dato.apellidos} ${dato.nombres}`
                //         }
                //     }
                // ]
                // fila1 =fila1.length!==0 ? [...est_titulos.map(val=>{return{...val, title:'ESTUDIANTES', orden:false}}), ...fila1] :[];
                // est_titulos={filas:2 ,datos:[fila1,[...est_titulos, ...fila2]], values:[...est_titulos,...fila2]};
                // console.log(est_titulos)
                if (active) {
                    cambio({datos, formulario, est_cantidad, est_datos, est_titulos, formulario_docente, cargando:false})   
                }
                
            }

            setCargando(false)
        })();

        return () => {
            active = false;
        };

    },[cargando])


    // if (state.formulario===undefined){
    //     Inicio()
        
    // }
    const alto = Ver_Valores().tipo==='Web' 
        ?   (window.innerHeight * 0.79) - 110//window.innerHeight * 0.61
        :   window.innerHeight * 0.68
    const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    // console.log(state.formulario ? state.formulario.titulos[0].value.tipo.value : '')
    return state.formulario ? (
        <Box sx={{ flexGrow: 1, position: "relative"}}>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <Item style={{height: 110, color:'#fff'}}>
                        <Formulario {...state.formulario}
                            config={{
                                Estilos:{
                                    Input_label:{color:'#fff'},
                                    Input_fondo:'#fff',
                                    Input_input:{color:'#fff'}
                                }
                            }}
                        />
                    </Item>
                </Grid>
                <Grid item xs={12} >
                    {state.est_datos 
                        ?   <Item style={{}}>
                                <Tabla  Titulo={`Notas de ${state.datos ? state.datos.grado +' '+ state.datos.seccion : ''}`}
                                    alto={alto}
                                    Config={Config ? Config : Ver_Valores().config}
                                    titulos={state.est_titulos}
                                    table={'uecla_estudiante'}
                                    cantidad={state.est_cantidad ? state.est_cantidad : null}
                                    // cargacompleta={Actualizar_data}
                                    datos={state.est_datos}
                                    AccionColumna={Abrir}
                                    AccionD={Abrir1}
                                    Noactualizar
                                    cargaporparte={false}
                                    sinpaginacion={true}  
                                    acciones={
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography >{`${state.datos && state.datos.tipo==='seccion' ? 'GENERAL' : state.datos.asignatura ? state.datos.asignatura.titulo : ''}`}</Typography>
                                            {
                                                state.datos && state.datos.tipo!=='seccion'
                                                    ?   <div>
                                                            <IconButton color={'primary'} title={'Evaluaciones de la asignatura'} onClick={Agregar}>
                                                                <Icon style={color}>view_list</Icon>
                                                            </IconButton>
                                                        </div>
                                                    :   null
                                            }
                                            { state.datos
                                                ?   <div>
                                                        <IconButton color={'primary'} 
                                                                    title=  {state.datos.tipo==='seccion' 
                                                                                ? 'Formato Calificaciones parciales y definitivas' 
                                                                                : 'Formato planilla de Evaluación de la Actuación del Estudiante'
                                                                            } 
                                                                    onClick={state.datos.tipo==='seccion' 
                                                                        ? FormatoCPD
                                                                        : FormatoEvaluacion
                                                                    }
                                                        >
                                                            <Icon style={color}>description</Icon>
                                                        </IconButton>
                                                    </div>
                                                :   null
                                            }
                                            {
                                                state.datos && state.datos.tipo==='seccion'
                                                    ?   <div>
                                                            <IconButton color={'primary'} title={'Boletas'} onClick={FormatoBoletas}>
                                                                <Icon style={color}>switch_account</Icon>
                                                            </IconButton>
                                                        </div>
                                                    :   null
                                            }
                                            {state.est_datos && state.est_datos.filter(f=>f.local).length!==0
                                                ?   
                                                    !state.cargando
                                                    ?   <div>
                                                            <IconButton color={'primary'} title={'Guardar Cambios'} onClick={GuardarTodo}>
                                                                <Icon style={color}>save</Icon>
                                                            </IconButton>
                                                        </div>
                                                    :  <CircularProgress  /> 
                                                :   null
                                            }
                                        </Stack>
                                    } 
                                />
                            </Item>
                        : null
                    }
                </Grid>
            </Grid>
            <Cargando open={cargando ? cargando : state.cargando ? state.cargando : false} Logo={Logo} Fondo={'#ffffff'}/>
            <Dialogo  {...dialogo} config={Ver_Valores().config}/>
        </Box>
    ): null;
}
